import React, { useState } from 'react';
import Settings from './appSettings/appSettings';
import SettingsMenu from './menu/settingsMenu';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom'; 

const SettingsContainer = () => {
  const [selectedKey, setSelectedKey] = useState('profile');

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
  };

  const renderContent = () => {
    switch (selectedKey) {
      case 'profile':
        return <Settings />;
      // Add more cases for other options as needed
      default:
        return <div>Select an option from the menu</div>;
    }
  };

  return (
    <div style={{ width: '100%', boxSizing: 'border-box' }}>
    <style>
      {`
        .container-fluid {
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
          margin-right: auto;
          margin-left: auto;
        }
        .row {
          display: flex;
          flex-wrap: nowrap;
        }
        .sidebar-menu {
          flex: 0 0 25%;
          max-width: 22.5%;
          min-height: 100vh;
          padding: 15px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
        }
        .content-area {
          flex: 0 0 75%;
          max-width: 75%;
          padding: 15px;
        }
        @media (max-width: 768px) {
          .row {
            flex-wrap: wrap;
          }
          .sidebar-menu, .content-area {
            flex: 0 0 100%;
            max-width: 100%;
          }
          .sidebar-menu {
            min-height: auto;
            margin-bottom: 15px;
          }
        }
      `}
    </style>
    <div>
    <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Settings</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-1">General Settings</h5>
      <div className="container-fluid">
        <div className="row">
          <div className="sidebar-menu">
            <SettingsMenu onMenuClick={handleMenuClick} />
          </div>
          <div className="content-area">
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default SettingsContainer;



