import React, { useEffect, useState, useRef } from 'react';
import { Table, Select, Spin, Button } from 'antd';
import {
  FormGroup,
  Input,
  Label,
  ButtonGroup,
} from 'reactstrap';
import { collection, getDocs } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import html2canvas from 'html2canvas';
import * as FileSaver from 'file-saver';
import Papa from 'papaparse';
import Widget from '../../components/Widget/Widget';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom'; 

const { Option } = Select;

const BudgetReports = () => {
  const { db } = fetchFirebaseConfig();
  const [budgets, setBudgets] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startMonth, setStartMonth] = useState('jan');
  const [endMonth, setEndMonth] = useState('dec');
  const [view, setView] = useState('table'); // New state for view type
  const [maxAmount, setMaxAmount] = useState(0); // State to track maximum amount for Y-axis in the chart
  const chartRef = useRef(null);

  const months = [
    { key: 'jan', label: 'January' },
    { key: 'feb', label: 'February' },
    { key: 'mar', label: 'March' },
    { key: 'apr', label: 'April' },
    { key: 'may', label: 'May' },
    { key: 'jun', label: 'June' },
    { key: 'jul', label: 'July' },
    { key: 'aug', label: 'August' },
    { key: 'sep', label: 'September' },
    { key: 'oct', label: 'October' },
    { key: 'nov', label: 'November' },
    { key: 'dec', label: 'December' },
  ];

  useEffect(() => {
    const fetchBudgets = async () => {
      setLoading(true);
      try {
        const budgetSnapshot = await getDocs(collection(db, 'Budgets'));
        const budgets = [];

        budgetSnapshot.forEach((doc) => {
          const data = doc.data();
          budgets.push({
            ...data,
            id: doc.id,
          });
        });

        setBudgets(budgets);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchBudgets();
  }, [db]);

  useEffect(() => {
    if (selectedBudget) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const budget = budgets.find((b) => b.id === selectedBudget);
          const incomeSnapshot = await getDocs(collection(db, 'TestIncome'));
          const incomeMap = new Map();
          incomeSnapshot.forEach((doc) => {
            incomeMap.set(doc.id, doc.data());
          });

          const expensesSnapshot = await getDocs(
            collection(db, 'TestExpenses')
          );
          const expensesMap = new Map();
          expensesSnapshot.forEach((doc) => {
            expensesMap.set(doc.id, doc.data());
          });

          console.log(budget);

          const records = budget.records.map((record) => {
            const accountData =
              incomeMap.get(record.key) || expensesMap.get(record.key) || {};
            return {
              ...record,
              account_code: accountData.account_code || record.account_code,
              account_level: accountData.account_level || record.account_level,
              account_name: accountData.account_name || record.account_name,
              balance: accountData.balance || 0,
            };
          });

          // Calculate maxAmount for chart Y-axis
          const maxAmount = Math.max(
            ...records.flatMap((record) => [
              ...months.map((month) => record[month.key] || 0),
              ...months.map((month) => record.balance || 0),
            ])
          );

          setMaxAmount(maxAmount);
          setData(records);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [selectedBudget, budgets, db]);

  const handleBudgetChange = (value) => {
    setSelectedBudget(value);
  };

  const handleStartMonthChange = (value) => {
    setStartMonth(value);
  };

  const handleEndMonthChange = (value) => {
    setEndMonth(value);
  };

  const handleViewChange = (value) => {
    setView(value);
  };

  const getFilteredColumns = () => {
    const startIndex = months.findIndex((month) => month.key === startMonth);
    const endIndex = months.findIndex((month) => month.key === endMonth);

    const filteredMonths = months.slice(startIndex, endIndex + 1);

    const columns = [
      {
        title: 'Account',
        dataIndex: 'account',
        key: 'account',
        width: 250,
        fixed: 'left',
        render: (text, record) => (
          <span style={getAccountStyle(record.account_level, record.isTitle)}>
            {record.isTitle
              ? text
              : `${record.account_code} - ${record.account_name}`}
          </span>
        ),
      },
      ...filteredMonths.map((month) => ({
        title: month.label,
        children: [
          {
            title: 'Budget',
            width: 100,
            dataIndex: month.key,
            key: `${month.key}-budget`,
            render: (text, record) => <span>{record[month.key]}</span>,
          },
          {
            title: 'Actual',
            width: 100,
            dataIndex: `${month.key}_actual`,
            key: `${month.key}-actual`,
            render: (text, record) => <span>{record.balance}</span>,
          },
          {
            title: 'Budget vs Actual',
            width: 100,
            dataIndex: `${month.key}_difference`,
            key: `${month.key}-difference`,
            render: (text, record) => (
              <span>{(record[month.key] || 0) - (record.balance || 0)}</span>
            ),
          },
          {
            title: 'Budget %',
            width: 100,
            dataIndex: `${month.key}_percentage`,
            key: `${month.key}-percentage`,
            render: (text, record) => {
              const budget = record[month.key] || 0;
              const actual = record.balance || 0;
              const difference = budget - actual;
              const percentage =
                budget !== 0 ? ((difference / budget) * 100).toFixed(2) : 'N/A';
              return <span>{percentage}%</span>;
            },
          },
        ],
      })),
      {
        title: 'Annual Total',
        dataIndex: 'total',
        key: 'total',
        width: 150,
        render: (text, record) => <span>{record.total}</span>,
      },
    ];

    return columns;
  };

  const getAccountStyle = (level, isTitle) => {
    if (isTitle) return { fontWeight: 'bold', fontSize: '16px' };
    switch (level) {
      case 'parent':
        return { fontWeight: 'bold' };
      case 'child':
        return { fontWeight: 'normal', fontSize: '14px', marginLeft: '20px' };
      case 'grandchild':
        return { fontWeight: 'lighter', fontSize: '12px', marginLeft: '40px' };
      default:
        return {};
    }
  };

  const handlePrintPDF = () => {
    const doc = new jsPDF();
    const columns = getFilteredColumns().map((col) => col.title);
    const rows = data.map((row) => {
      const rowData = [];
      columns.forEach((col) => {
        if (col.children) {
          col.children.forEach((child) => {
            rowData.push(row[child.dataIndex] || 0);
          });
        } else {
          rowData.push(row[col.dataIndex] || '');
        }
      });
      return rowData;
    });
    autoTable(doc, {
      head: [columns],
      body: rows,
    });
    doc.save('budget-reports.pdf');
  };

  const handlePrintExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Budget Reports');
    XLSX.writeFile(wb, 'budget-reports.xlsx');
  };

  const formatYAxisTick = (value) => {
    return value.toLocaleString();
  };

  const formatTooltip = (value, name, props) => {
    return [value.toLocaleString(), name];
  };

  const handleDownloadImage = () => {
    if (chartRef.current) {
      const chart = chartRef.current.container;
      html2canvas(chart).then((canvas) => {
        canvas.toBlob((blob) => {
          FileSaver.saveAs(blob, 'budget_comparison.png');
        });
      });
    }
  };

  const handleDownloadCSV = () => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'budget_comparison.csv');
  };

  const handleDownloadSVG = () => {
    if (chartRef.current) {
      const svg = chartRef.current.container.querySelector('svg');
      const svgData = new XMLSerializer().serializeToString(svg);
      const blob = new Blob([svgData], { type: 'image/svg+xml' });
      FileSaver.saveAs(blob, 'budget_comparison.svg');
    }
  };

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Budget</Breadcrumb.Item>
        <Breadcrumb.Item>Report</Breadcrumb.Item>
      </Breadcrumb>
  <WidgetShadow>
      <div style={{ marginBottom: '20px' }}>
        <Select
          style={{ width: '200px' }}
          placeholder='Select Budget'
          onChange={handleBudgetChange}
          value={selectedBudget}
        >
          {budgets.map((budget) => (
            <Option key={budget.id} value={budget.id}>
              {budget.name}
            </Option>
          ))}
        </Select>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <Select
          style={{ width: '200px' }}
          placeholder='Start Month'
          onChange={handleStartMonthChange}
          value={startMonth}
        >
          {months.map((month) => (
            <Option key={month.key} value={month.key}>
              {month.label}
            </Option>
          ))}
        </Select>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <Select
          style={{ width: '200px' }}
          placeholder='End Month'
          onChange={handleEndMonthChange}
          value={endMonth}
        >
          {months.map((month) => (
            <Option key={month.key} value={month.key}>
              {month.label}
            </Option>
          ))}
        </Select>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <Select
          style={{ width: '200px' }}
          placeholder='Select View'
          onChange={handleViewChange}
          value={view}
        >
          <Option value='table'>Table View</Option>
          <Option value='chart'>Chart View</Option>
        </Select>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <Button
          type='primary'
          onClick={handlePrintPDF}
          disabled={!selectedBudget}
        >
          Print PDF
        </Button>
        <Button
          type='primary'
          onClick={handlePrintExcel}
          style={{ marginLeft: '10px' }}
          disabled={!selectedBudget}
        >
          Print Excel
        </Button>
      </div>

      {loading ? (
        <Spin />
      ) : selectedBudget ? (
        view === 'table' ? (
          <Table
            id='budget-table'
            columns={getFilteredColumns()}
            dataSource={data}
            pagination={false}
            rowKey='account_id'
            scroll={{ x: '100%' }}
          />
        ) : (
          <div>
            <ButtonGroup size='sm' className='mb-3'>
              <Button color='success' onClick={handleDownloadImage}>
                PNG
              </Button>
              <Button color='success' onClick={handleDownloadSVG}>
                SVG
              </Button>
              <Button color='success' onClick={handleDownloadCSV}>
                CSV
              </Button>
            </ButtonGroup>
            <ResponsiveContainer width='100%' height={400}>
              <AreaChart
                data={data}
                margin={{ top: 10, right: 10, left: 10, bottom: 0 }}
                ref={chartRef}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='account_name' tick={{ fontSize: 12 }} />
                <YAxis
                  domain={[0, maxAmount]}
                  tickFormatter={formatYAxisTick}
                  tick={{ fontSize: 12 }}
                />
                <Tooltip
                  formatter={formatTooltip}
                  wrapperStyle={{ fontSize: '10px' }}
                  contentStyle={{ fontSize: '13px' }}
                />
                <Area
                  type='monotone'
                  dataKey='budget'
                  stackId='1'
                  stroke='#8884d8'
                  fill='#8884d8'
                  name='Budget'
                />
                <Area
                  type='monotone'
                  dataKey='balance'
                  stackId='2'
                  stroke='#82ca9d'
                  fill='#82ca9d'
                  name='Actual'
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        )
      ) : null}
      </WidgetShadow>
    </div>
  );
};

export default BudgetReports;

// import React, { useEffect, useState } from 'react';
// import { Table, Select, Spin, Button } from 'antd';
// import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
// import { collection, getDocs } from 'firebase/firestore';
// import { fetchFirebaseConfig } from '../../firebase';
// import jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable';
// import * as XLSX from 'xlsx';

// const { Option } = Select;

// const BudgetReports = () => {
//   const { db } = fetchFirebaseConfig();
//   const [budgets, setBudgets] = useState([]);
//   const [selectedBudget, setSelectedBudget] = useState(null);
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [startMonth, setStartMonth] = useState('jan');
//   const [endMonth, setEndMonth] = useState('dec');

//   const months = [
//     { key: 'jan', label: 'January' },
//     { key: 'feb', label: 'February' },
//     { key: 'mar', label: 'March' },
//     { key: 'apr', label: 'April' },
//     { key: 'may', label: 'May' },
//     { key: 'jun', label: 'June' },
//     { key: 'jul', label: 'July' },
//     { key: 'aug', label: 'August' },
//     { key: 'sep', label: 'September' },
//     { key: 'oct', label: 'October' },
//     { key: 'nov', label: 'November' },
//     { key: 'dec', label: 'December' },
//   ];

//   useEffect(() => {
//     const fetchBudgets = async () => {
//       setLoading(true);
//       try {
//         const budgetSnapshot = await getDocs(collection(db, 'Budgets'));
//         const budgets = [];

//         budgetSnapshot.forEach((doc) => {
//           const data = doc.data();
//           budgets.push({
//             ...data,
//             id: doc.id,
//           });
//         });

//         setBudgets(budgets);
//         setLoading(false);
//       } catch (error) {
//         console.log(error);
//         setLoading(false);
//       }
//     };

//     fetchBudgets();
//   }, [db]);

//   useEffect(() => {
//     if (selectedBudget) {
//       const fetchData = async () => {
//         setLoading(true);
//         try {
//           const budget = budgets.find((b) => b.id === selectedBudget);
//           const incomeSnapshot = await getDocs(collection(db, 'TestIncome'));
//           const incomeMap = new Map();
//           incomeSnapshot.forEach((doc) => {
//             incomeMap.set(doc.id, doc.data());
//           });

//           const expensesSnapshot = await getDocs(
//             collection(db, 'TestExpenses')
//           );
//           const expensesMap = new Map();
//           expensesSnapshot.forEach((doc) => {
//             expensesMap.set(doc.id, doc.data());
//           });

//           console.log(budget);

//           const records = budget.records.map((record) => {
//             const accountData =
//               incomeMap.get(record.key) || expensesMap.get(record.key) || {};
//             return {
//               ...record,
//               account_code: accountData.account_code || record.account_code,
//               account_level: accountData.account_level || record.account_level,
//               account_name: accountData.account_name || record.account_name,
//               balance: accountData.balance || 0,
//             };
//           });

//           setData(records);
//           setLoading(false);
//         } catch (error) {
//           console.log(error);
//           setLoading(false);
//         }
//       };

//       fetchData();
//     }
//   }, [selectedBudget, budgets, db]);

//   const handleBudgetChange = (value) => {
//     setSelectedBudget(value);
//   };

//   const handleStartMonthChange = (value) => {
//     setStartMonth(value);
//   };

//   const handleEndMonthChange = (value) => {
//     setEndMonth(value);
//   };

//   const getFilteredColumns = () => {
//     const startIndex = months.findIndex((month) => month.key === startMonth);
//     const endIndex = months.findIndex((month) => month.key === endMonth);

//     const filteredMonths = months.slice(startIndex, endIndex + 1);

//     const columns = [
//       {
//         title: 'Account',
//         dataIndex: 'account',
//         key: 'account',
//         width: 250,
//         fixed: 'left',
//         render: (text, record) => (
//           <span style={getAccountStyle(record.account_level, record.isTitle)}>
//             {record.isTitle
//               ? text
//               : `${record.account_code} - ${record.account_name}`}
//           </span>
//         ),
//       },
//       ...filteredMonths.map((month) => ({
//         title: month.label,
//         children: [
//           {
//             title: 'Budget',
//             width: 100,
//             dataIndex: month.key,
//             key: `${month.key}-budget`,
//             render: (text, record) => <span>{record[month.key]}</span>,
//           },
//           {
//             title: 'Actual',
//             width: 100,
//             dataIndex: `${month.key}_actual`,
//             key: `${month.key}-actual`,
//             render: (text, record) => <span>{record.balance}</span>,
//           },
//           {
//             title: 'Budget vs Actual',
//             width: 100,
//             dataIndex: `${month.key}_difference`,
//             key: `${month.key}-difference`,
//             render: (text, record) => (
//               <span>{(record[month.key] || 0) - (record.balance || 0)}</span>
//             ),
//           },
//           {
//             title: 'Budget %',
//             width: 100,
//             dataIndex: `${month.key}_percentage`,
//             key: `${month.key}-percentage`,
//             render: (text, record) => {
//               const budget = record[month.key] || 0;
//               const actual = record.balance || 0;
//               const difference = budget - actual;
//               const percentage =
//                 budget !== 0
//                   ? `${((difference / budget) * 100).toFixed(2)}%`
//                   : 'N/A';
//               return <span>{percentage}</span>;
//             },
//           },
//         ],
//       })),
//       {
//         title: 'Annual Total',
//         dataIndex: 'total',
//         key: 'total',
//         width: 150,
//         render: (text, record) => <span>{record.total}</span>,
//       },
//     ];

//     return columns;
//   };

//   const getAccountStyle = (level, isTitle) => {
//     if (isTitle) return { fontWeight: 'bold', fontSize: '16px' };
//     switch (level) {
//       case 'parent':
//         return { fontWeight: 'bold' };
//       case 'child':
//         return { fontWeight: 'normal', fontSize: '14px', marginLeft: '20px' };
//       case 'grandchild':
//         return { fontWeight: 'lighter', fontSize: '12px', marginLeft: '40px' };
//       default:
//         return {};
//     }
//   };

//   const handlePrintPDF = () => {
//     const doc = new jsPDF();
//     const columns = getFilteredColumns().map((col) => col.title);
//     const rows = data.map((row) => {
//       const rowData = [];
//       columns.forEach((col) => {
//         if (col.children) {
//           col.children.forEach((child) => {
//             rowData.push(row[child.dataIndex] || 0);
//           });
//         } else {
//           rowData.push(row[col.dataIndex] || '');
//         }
//       });
//       return rowData;
//     });
//     autoTable(doc, {
//       head: [columns],
//       body: rows,
//     });
//     doc.save('budget-reports.pdf');
//   };

//   const handlePrintExcel = () => {
//     const ws = XLSX.utils.json_to_sheet(data);
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Budget Reports');
//     XLSX.writeFile(wb, 'budget-reports.xlsx');
//   };

//   return (
//     <div>
//       <Breadcrumb>
//         <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
//         <BreadcrumbItem active>Budget Reports</BreadcrumbItem>
//       </Breadcrumb>
//       <h5 className='mb-lg'>Budget Reports</h5>

//       <div style={{ marginBottom: '20px' }}>
//         <Select
//           placeholder='Select Budget'
//           style={{ width: 200, marginRight: '10px' }}
//           onChange={handleBudgetChange}
//         >
//           {budgets.map((budget) => (
//             <Option key={budget.id} value={budget.id}>
//               {`${budget.name} (${budget.year})`}
//             </Option>
//           ))}
//         </Select>
//         <Select
//           placeholder='Start Month'
//           style={{ width: 150, marginRight: '10px' }}
//           onChange={handleStartMonthChange}
//           value={startMonth}
//           disabled={!selectedBudget}
//         >
//           {months.map((month) => (
//             <Option key={month.key} value={month.key}>
//               {month.label}
//             </Option>
//           ))}
//         </Select>
//         <Select
//           placeholder='End Month'
//           style={{ width: 150 }}
//           onChange={handleEndMonthChange}
//           value={endMonth}
//           disabled={!selectedBudget}
//         >
//           {months.map((month) => (
//             <Option key={month.key} value={month.key}>
//               {month.label}
//             </Option>
//           ))}
//         </Select>
//       </div>

//       <div style={{ marginBottom: '20px' }}>
//         {/* <Button
//           type='primary'
//           onClick={handlePrintPDF}
//           disabled={!selectedBudget}
//         >
//           Print PDF
//         </Button> */}
//         <Button
//           type='primary'
//           onClick={handlePrintExcel}
//           style={{ marginLeft: '10px' }}
//           disabled={!selectedBudget}
//         >
//           Print Excel
//         </Button>
//       </div>

//       {loading ? (
//         <Spin />
//       ) : selectedBudget ? (
//         <Table
//           id='budget-table'
//           columns={getFilteredColumns()}
//           dataSource={data}
//           pagination={false}
//           rowKey='account_id'
//           scroll={{ x: '100%' }}
//         />
//       ) : null}
//     </div>
//   );
// };

// export default BudgetReports;
