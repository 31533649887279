import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Select, Upload } from 'antd';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom'; 
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
  serverTimestamp,
} from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';
import * as XLSX from 'xlsx';
import Widget from '../../components/Widget/Widget';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';

const { Option } = Select;

const CreateBudget = () => {
  const { db } = fetchFirebaseConfig();
  const [data, setData] = useState([]);
  const [budgetName, setBudgetName] = useState('');
  const [budgetYear, setBudgetYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [inputMethod, setInputMethod] = useState('manual');
  const [fileList, setFileList] = useState([]);
  const [uploadError, setUploadError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      let incomeList = [];
      let expensesList = [];
      setLoading(true);
      try {
        const incomeSnapshot = await getDocs(collection(db, 'TestIncome'));
        incomeSnapshot.forEach((doc) => {
          const data = doc.data();
          incomeList.push({
            key: doc.id, // Ensure unique key
            account_code: data.account_code,
            account_level: data.account_level,
            account_name: data.account_name,
            jan: data.jan || 0,
            feb: data.feb || 0,
            mar: data.mar || 0,
            apr: data.apr || 0,
            may: data.may || 0,
            jun: data.jun || 0,
            jul: data.jul || 0,
            aug: data.aug || 0,
            sep: data.sep || 0,
            oct: data.oct || 0,
            nov: data.nov || 0,
            dec: data.dec || 0,
            total: calculateTotal(data),
          });
        });

        const expensesSnapshot = await getDocs(collection(db, 'TestExpenses'));
        expensesSnapshot.forEach((doc) => {
          const data = doc.data();
          expensesList.push({
            key: doc.id, // Ensure unique key
            account_code: data.account_code,
            account_level: data.account_level,
            account_name: data.account_name,
            jan: data.jan || 0,
            feb: data.feb || 0,
            mar: data.mar || 0,
            apr: data.apr || 0,
            may: data.may || 0,
            jun: data.jun || 0,
            jul: data.jul || 0,
            aug: data.aug || 0,
            sep: data.sep || 0,
            oct: data.oct || 0,
            nov: data.nov || 0,
            dec: data.dec || 0,
            total: calculateTotal(data),
          });
        });

        incomeList.sort((a, b) => a.account_code.localeCompare(b.account_code));
        expensesList.sort((a, b) =>
          a.account_code.localeCompare(b.account_code)
        );

        const combinedData = [
          { key: 'income-title', account: 'Income', isTitle: true },
          ...incomeList,
          { key: 'expenses-title', account: 'Expenses', isTitle: true },
          ...expensesList,
        ];

        setData(combinedData);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetchData();
  }, [db]);

  const calculateTotal = (record) => {
    const months = [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ];
    return months.reduce(
      (acc, month) => acc + (parseFloat(record[month]) || 0),
      0
    );
  };

  const handleInputChange = (e, key, month) => {
    setData((prevData) => {
      const newData = prevData.map((item) => {
        if (item.key === key) {
          const updatedItem = {
            ...item,
            [month]: parseFloat(e.target.value) || 0,
          };
          updatedItem.total = calculateTotal(updatedItem);
          return updatedItem;
        }
        return item;
      });
      return newData;
    });
  };

  const checkIfBudgetExists = async (name, year) => {
    setLoading(true);
    try {
      const nameQuery = query(
        collection(db, 'Budgets'),
        where('name', '==', name)
      );
      const yearQuery = query(
        collection(db, 'Budgets'),
        where('year', '==', year)
      );

      const [nameSnapshot, yearSnapshot] = await Promise.all([
        getDocs(nameQuery),
        getDocs(yearQuery),
      ]);

      setLoading(false);
      return !nameSnapshot.empty || !yearSnapshot.empty;
    } catch (error) {
      console.error('Error checking budget:', error);
      setLoading(false);
      return true;
    }
  };

  const handleSave = async () => {
    if (await checkIfBudgetExists(budgetName, budgetYear)) {
      setErrorMessage('Budget with this name or year already exists.');
      return;
    }

    setLoading(true);
    try {
      const budgetDoc = {
        name: budgetName,
        year: budgetYear,
        records: data.filter((record) => !record.isTitle),
      };

      await addDoc(collection(db, 'Budgets'), budgetDoc);
      setErrorMessage('');
      setLoading(false);
    } catch (error) {
      console.error('Error saving budget:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const validateInputs = async () => {
      if (budgetName.trim() === '') {
        setIsSaveDisabled(true);
        setErrorMessage('Budget name cannot be empty.');
        return;
      }

      if (budgetName || budgetYear) {
        const exists = await checkIfBudgetExists(budgetName, budgetYear);
        setIsSaveDisabled(exists);
        setErrorMessage(exists ? 'Budget in this year already exists.' : '');
      } else {
        setIsSaveDisabled(true);
        return;
      }

      if (inputMethod !== 'manual') {
        setIsSaveDisabled(fileList.length === 0 || uploadError !== '');
      }
    };
    validateInputs();
  }, [budgetName, budgetYear, inputMethod, fileList, uploadError]);

  const getAccountStyle = (level, isTitle) => {
    if (isTitle) return { fontWeight: 'bold', fontSize: '16px' };
    switch (level) {
      case 'parent':
        return { fontWeight: 'bold' };
      case 'child':
        return { fontWeight: 'normal', fontSize: '14px', marginLeft: '20px' };
      case 'grandchild':
        return { fontWeight: 'lighter', fontSize: '12px', marginLeft: '40px' };
      default:
        return {};
    }
  };

  const generateExcelTemplate = () => {
    const worksheetData = [
      [
        'Account ID',
        'Account Code',
        'Account Name',
        'Account Level',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      ...data
        .filter((record) => !record.isTitle)
        .map((record) => [
          record.key,
          record.account_code,
          record.account_name,
          record.account_level,
          record.jan,
          record.feb,
          record.mar,
          record.apr,
          record.may,
          record.jun,
          record.jul,
          record.aug,
          record.sep,
          record.oct,
          record.nov,
          record.dec,
        ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Budget');

    XLSX.writeFile(workbook, 'BudgetTemplate.xlsx');
  };

  const validateExcelData = (jsonData) => {
    const requiredHeaders = [
      'account id',
      'account code',
      'account name',
      'account level',
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ];
    const headers = jsonData[0].map((header) => header.toLowerCase());
    const hasAllHeaders = requiredHeaders.every((header) =>
      headers.includes(header)
    );

    if (!hasAllHeaders) {
      return 'Invalid Excel template: Missing required columns.';
    }

    for (let i = 1; i < jsonData.length; i++) {
      const row = jsonData[i];
      if (
        typeof row[0] !== 'string' ||
        typeof row[1] !== 'string' ||
        typeof row[2] !== 'string' ||
        typeof row[3] !== 'string'
      ) {
        return `Invalid data type in row ${i +
          1}: Account ID, Account Code, Account Name, and Account Level must be strings.`;
      }
      for (let j = 4; j < row.length; j++) {
        if (typeof row[j] !== 'number') {
          return `Invalid data type in row ${i + 1}, column ${j +
            1}: Monthly values must be numbers.`;
        }
      }
    }
    return '';
  };

  const handleUpload = async (file) => {
    setUploadError('');
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const validationError = validateExcelData(jsonData);
      if (validationError) {
        setUploadError(validationError);
        return;
      }

      const parsedData = jsonData.slice(1).map((row, index) => ({
        key: `uploaded-${index}`, // Ensure unique key for uploaded data
        account_id: row[0],
        account_code: row[1],
        account_name: row[2],
        account_level: row[3],
        jan: row[4] || 0,
        feb: row[5] || 0,
        mar: row[6] || 0,
        apr: row[7] || 0,
        may: row[8] || 0,
        jun: row[9] || 0,
        jul: row[10] || 0,
        aug: row[11] || 0,
        sep: row[12] || 0,
        oct: row[13] || 0,
        nov: row[14] || 0,
        dec: row[15] || 0,
        total: 0,
      }));

      setData(parsedData);
      setFileList([file]);
    } catch (error) {
      setUploadError('Error reading Excel file');
      console.error('Error reading Excel file:', error);
    }
  };

  const columns = [
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      width: 250,
      fixed: 'left',
      render: (text, record) => (
        <span style={getAccountStyle(record.account_level, record.isTitle)}>
          {record.isTitle
            ? text
            : `${record.account_code} - ${record.account_name}`}
        </span>
      ),
    },
    ...[
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ].map((month) => ({
      title: month.charAt(0).toUpperCase() + month.slice(1),
      dataIndex: month,
      key: month,
      width: 150,
      render: (text, record) =>
        record.isTitle ? (
          <span>{''}</span>
        ) : (
          <Input
            value={record[month]}
            type='number'
            onChange={(e) => handleInputChange(e, record.key, month)}
          />
        ),
    })),
    {
      title: 'Annual Total',
      dataIndex: 'total',
      key: 'total',
      width: 150,
      render: (text, record) => <span>{record.total}</span>,
    },
  ];

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Budget</Breadcrumb.Item>
        <Breadcrumb.Item>Create Budget</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className='mb-lg'>Create Budget</h5>
   <WidgetShadow>
      <div style={{ marginBottom: '20px' }}>
        <Input
          placeholder='Budget Name'
          value={budgetName}
          onChange={(e) => setBudgetName(e.target.value)}
          style={{ width: '200px', marginRight: '10px' }}
        />
        <Input
          placeholder='Budget Year'
          type='number'
          value={budgetYear}
          onChange={(e) => setBudgetYear(e.target.value)}
          style={{ width: '100px' }}
        />
        <Select
          defaultValue='manual'
          style={{ width: 200, marginLeft: '10px' }}
          onChange={(value) => setInputMethod(value)}
        >
          <Option value='manual'>Manual Input</Option>
          <Option value='excel'>Upload Excel</Option>
        </Select>
      </div>

      {errorMessage && (
        <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>
      )}
      {uploadError && (
        <div style={{ color: 'red', marginBottom: '10px' }}>{uploadError}</div>
      )}

      {inputMethod === 'manual' ? (
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{
            x: 1500,
            y: 300,
          }}
          rowKey='key'
        />
      ) : (
        <div>
          <Button
            onClick={generateExcelTemplate}
            style={{ marginBottom: '10px' }}
          >
            Download Excel Template
          </Button>
          <Upload
            beforeUpload={(file) => {
              handleUpload(file);
              return false;
            }}
            fileList={fileList}
            onChange={({ fileList }) => setFileList(fileList.slice(-1))}
            maxCount={1}
          >
            <Button>Upload Filled Template</Button>
          </Upload>
        </div>
      )}
      <Button type='primary' onClick={handleSave} disabled={isSaveDisabled}>
        Save
      </Button>
    </WidgetShadow>
    </div>
  );
};

export default CreateBudget;
