import React, { useState } from 'react';
import Widget from '../../components/Widget/Widget';
import { useParams } from "react-router-dom";
import ChapChapAccounts from './chapchapTermAcc';
import ShortTermAccounts from './shortTermAcc';
import LongTermAccounts from './longTermAcc';
import { fetchFirebaseConfig } from '../../firebase';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import TabNavigation from '../../components/TabNavigation/tabnavigation';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';

const LoanAccounts = () => {
  const { id } = useParams(); // Get the UID from the URL
  const [activeTab, setActiveTab] = useState('longTerm');
  const [refreshDepositsTable, setRefreshDepositsTable] = useState(false);

  const tabContent = {
    longTerm: { label: 'Long Term Loan Accounts', content: <LongTermAccounts loanId={id} refreshTable={refreshDepositsTable} /> },
    shortTerm: { label: 'Development Fund Accounts', content: <ShortTermAccounts loanId={id} /> },
    chapchapTerm: { label: 'Chap Chap Loan Accounts', content: <ChapChapAccounts loanId={id} refreshTable={refreshDepositsTable} /> }
  };

  const { db } = fetchFirebaseConfig();

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === 'payments' || key === 'payoutsRecords') {
      // Set refresh state to true when the deposits tab is clicked
      setRefreshDepositsTable(prevState => !prevState);
    }
  };

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Loans</Breadcrumb.Item>
        <Breadcrumb.Item>Accounts</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Loan Accounts</h5>
      <WidgetShadow>
        <TabNavigation activeTab={activeTab} handleTabChange={handleTabChange} tabContent={tabContent} />
      </WidgetShadow>
    </div>
  );
};

export default LoanAccounts;

