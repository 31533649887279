import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Select } from 'antd';
import { getDocs, collection, query, where, updateDoc, doc, addDoc, getDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';
import Widget from '../../components/Widget/Widget';
import { toast,} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRef } from "react";
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'; 
import Receipt from '../../components/recepitSample/receipt';
import jsPDF from 'jspdf';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { TextButton } from '../../components/Buttons/CustomButtons';

const DepositsFormPayment = () => {
  const [accountOptions, setAccountOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [userRef, setUserRef] = useState('');
  const [amount, setAmount] = useState('');
  const [accountType, setAccountType] = useState('');
  const [firstName, setFirstName] = useState('');
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [modal, setModal] = useState(false);
  const [date, setDate] = useState('');
  const { db, storage } = fetchFirebaseConfig();

  const {id} = useParams();

  const toggle = (e) => setModal(!modal);

  const componentRef = useRef(null);

  const receipt = (e) =>{
    e.preventDefault();
    setModal(!modal);
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!id) {
          console.error("UID is undefined");
          return;
        }
  
        const userDocRef = doc(db, 'Liabilities', id);
        const userDocSnapshot = await getDoc(userDocRef);
  
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          console.log('Fetched user data:', userData);
          setAccountType(userData.AccountType || '');
          setFirstName(userData.account_name || '');
          setUserRef(userData.user || ''); 
          setSelectedUser(userData.account_name || '');

          const userReference = userData.user ;
          const userSnapshot = await getDoc(userReference);

          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setUserDetails(userData);

            setDetails((prevLoanDetails) => ({
              ...prevLoanDetails,
              userPosition: userData.position,
              userPhotoUrl: userData.photo_url,
              userid: userData.uid,
            }));

            onSnapshot(userDocRef, (updatedSnapshot) => {
              if (updatedSnapshot.exists()) {
                const updatedData = updatedSnapshot.data();
                setDetails(updatedData);
              }
            });
          } else {
            setUserDetails(null);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
  
    fetchUserData();
  }, [db, id]);
  
  
  useEffect(() => {
    const fetchAccountNames = async () => {
      try {
        const tables = ['Assets', 'Liabilities', 'Expenses', 'Income', 'Equity'];
        const data = {};
  
        const promises = tables.map(async (table) => {
          const collectionSnapshot = await getDocs(collection(db, table));
          const accounts = [];
          const childAccountsBuffer = [];
          const grandchildBuffer = [];
  
          collectionSnapshot.docs.forEach((doc) => {
            const { account_name, account_level, parentAccount, account_code, IsMainAccount } = doc.data();
  
            // Skip accounts with IsMainAccount: 'No'
            if (IsMainAccount === 'No') {
              return;
            }
  
            if (account_level === 'parent') {
              accounts.push({ name: account_name, code: account_code, level: 1, children: [] });
            } else if (account_level === 'child') {
              childAccountsBuffer.push({ name: account_name, code: account_code, parentAccount });
            } else if (account_level === 'grandchild') {
              grandchildBuffer.push({ name: account_name, code: account_code, parentAccount });
            }
          });
  
          // Process childAccountsBuffer to add children to parents
          childAccountsBuffer.forEach((child) => {
            const parentIndex = accounts.findIndex(account => account.name === child.parentAccount);
            if (parentIndex !== -1) {
              accounts[parentIndex].children.push({ name: child.name, code: child.code, level: 2, children: [] });
            } else {
              accounts.push({ name: child.name, code: child.code, level: 1, children: [] });
            }
          });
  
          // Process grandchildBuffer to add grandchildren to children
          grandchildBuffer.forEach((grandchild) => {
            let grandchildAdded = false;
            accounts.forEach(parentAccount => {
              parentAccount.children.forEach(childAccount => {
                if (childAccount.name === grandchild.parentAccount) {
                  childAccount.children.push({ name: grandchild.name, code: grandchild.code, level: 3 });
                  grandchildAdded = true;
                }
              });
            });
            if (!grandchildAdded) {
              // console.warn(`Grandchild account ${grandchild.name} not added: Parent account ${grandchild.parentAccount} not found.`);
            }
          });
  
          // Filter out non-lowest-level accounts
          const lowestLevelAccounts = [];
          accounts.forEach((account) => {
            if (account.children.length === 0) {
              lowestLevelAccounts.push(account);
            } else {
              account.children.forEach((child) => {
                if (child.children.length === 0) {
                  lowestLevelAccounts.push(child);
                } else {
                  child.children.forEach((grandchild) => {
                    lowestLevelAccounts.push(grandchild);
                  });
                }
              });
            }
          });
  
          return { table, accounts: lowestLevelAccounts };
        });
  
        const resolvedPromises = await Promise.all(promises);
  
        resolvedPromises.forEach(({ table, accounts }) => {
          data[table] = accounts;
        });
        console.log('Fetched account names:', data);
        setAccountOptions(data);
      } catch (error) {
        console.error('Error fetching account names:', error);
      }
    };
  
    fetchAccountNames();
  }, [db]);
  

     // Example generateAccountCode function to generate a unique account code
     const generateAccountCode = () => {
     // Generate a random three-digit number and concatenate with "0"
      const randomDigits = Math.floor(Math.random() * 900) + 100; // Generates a number between 100 and 999
      return `${randomDigits}0`;
     };

     const transferCode = `TE${generateAccountCode()}`
  
  const renderAccountOptions = (accounts) => {
    const uniqueAccounts = new Map();
    accounts.forEach((account) => {
      if (!uniqueAccounts.has(account.name)) {
        uniqueAccounts.set(account.name, account);
      }
    });
  
    return Array.from(uniqueAccounts.values()).map((account) => (
      <Select.Option key={account.name} value={account.name}>
        {account.code}&nbsp;{account.name}
      </Select.Option>
    ));
  };
  
  const accountOptionsMemo = useMemo(() => (
    Object.keys(accountOptions).map((table) => (
      <Select.OptGroup key={table} label={<span style={{ fontWeight: 'bold', fontSize: '15.5px' }}>{table}</span>}>
        {renderAccountOptions(accountOptions[table])}
      </Select.OptGroup>
    ))
  ), [accountOptions]);


  const renderUserOptions = () => {
    return userOptions.map((user, index) => (
      <Select.Option key={index} value={user}>
        {user}
      </Select.Option>
    ));
  };

  const handleAccountUpdate = async (selectedAccountName, amount, selectedUser) => {
    
    const collections = ['Assets', 'Liabilities', 'Expenses', 'Income', 'Equity'];
    let selectedAccountCode = null;
    let selectedCollectionName = null;

        // Get the current date without time
        const currentDate = new Date();
        const currentDateString = currentDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD

    for (const collectionName of collections) {
        const collectionRef = collection(db, collectionName);
        const q = query(collectionRef, where('account_name', '==', selectedAccountName));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const accountDoc = querySnapshot.docs[0];
            const accountData = accountDoc.data();
            selectedAccountCode = accountData.account_code;
            selectedCollectionName = collectionName;

            let balanceEntryToUpdate = null;
            const balances = accountData.balances || [];
      
            // Find the balance entry with the same date as the current date
            balances.forEach((balanceEntry, index) => {
              if (balanceEntry.date && balanceEntry.date.toDate().toISOString().split('T')[0] === currentDateString) {
                balanceEntryToUpdate = { entry: balanceEntry, index: index };
              }
            });

            if (balanceEntryToUpdate) {
                const currentBalance = balanceEntryToUpdate.entry.balance || 0;
                const currentCreditBalance = balanceEntryToUpdate.entry.creditBalance || 0;
                const currentDebitBalance = balanceEntryToUpdate.entry.debitBalance || 0;

                // Update balance considering debit/credit based on account type
                const newBalance = currentBalance + amount; // Remains the same for all account types
                let newCreditBalance = currentCreditBalance;
                let newDebitBalance = currentDebitBalance;
        
                if (selectedCollectionName === 'Assets' || selectedCollectionName === 'Expenses') {
                  // For Assets and Expenses, add amount to currentCreditBalance
                  newDebitBalance = currentDebitBalance + amount;
                } else {
                  // For Liabilities, Income, and Equity, add amount to currentDebitBalance
                  newCreditBalance = currentCreditBalance + amount;
                }

                balances[balanceEntryToUpdate.index].balance = newBalance;
                balances[balanceEntryToUpdate.index].creditBalance = newCreditBalance;
                balances[balanceEntryToUpdate.index].debitBalance = newDebitBalance;

                await updateDoc(accountDoc.ref, { balances });
            } else {
                console.error(`No balance entries found for account_name '${selectedAccountName}' in collection '${collectionName}'.`);
            }

            break;
        }
    }
    // Call handleLoanAccountUpdate once after all balance updates
    if (selectedAccountCode && selectedUser) {
        await handleLoanAccountUpdate(selectedUser, selectedAccountName, amount, selectedAccountCode);
    }
};



const handleLoanAccountUpdate = async (selectedUser, selectedAccountName, amount, selectedAccountCode) => {
  try {
      if (!selectedUser) {
          throw new Error("Selected user is undefined");
      }
       
      
       const currentDate = new Date();
       const currentDateString = currentDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD


          const loanAccountsQuery = query(collection(db, 'Liabilities'), where('user', '==', userRef), where('AccountType', '==', accountType), where('account_name', '==', selectedUser)); // Replace 'accountType' with the actual loan type
          const loanAccountsSnapshot = await getDocs(loanAccountsQuery);

          console.log('Deposit accounts snapshot size:', loanAccountsSnapshot.size);

          if (!loanAccountsSnapshot.empty) {
              const accountDoc = loanAccountsSnapshot.docs[0];
              const accountData = accountDoc.data();
              const selectedUserCode = accountData.account_code;

              // Update the balances array
              let balanceEntryToUpdate = null;
              const balances = accountData.balances || [];

            // Find the balance entry with the same date as the current date
            balances.forEach((balanceEntry, index) => {
              if (balanceEntry.date && balanceEntry.date.toDate().toISOString().split('T')[0] === currentDateString) {
                balanceEntryToUpdate = { entry: balanceEntry, index: index };
              }
            });


            if (balanceEntryToUpdate) {
              const currentBalance = balanceEntryToUpdate.entry.balance || 0;
              const currentCreditBalance = balanceEntryToUpdate.entry.creditBalance || 0;
              const currentDebitBalance = balanceEntryToUpdate.entry.debitBalance || 0;

              const newBalance = currentBalance + amount;

              const newCreditBalance = currentCreditBalance + amount; // No change for debits
              const newDebitBalance = currentDebitBalance

              balances[balanceEntryToUpdate.index].balance = newBalance;
              balances[balanceEntryToUpdate.index].creditBalance = newCreditBalance;
              balances[balanceEntryToUpdate.index].debitBalance = newDebitBalance;

               await updateDoc(accountDoc.ref, { balances });

              } else {
                  console.error(`No balance entries found for account_name '${selectedUser}' in loan accounts.`);
              }

              // Add journal entry
              if (amount) {
                  try {
                      const journalData = {
                          journalCode: transferCode, // Replace with your actual journal code
                          description: `${accountType} Transfer`,
                          date: new Date(), // Replace with your actual date format
                          total_credit: amount,
                          total_debit: amount,
                          transactionType: 'Transfer',
                          entries: [
                              {
                                  credit_account: selectedUser,
                                  credit_account_code: selectedUserCode,
                                  debit_account: '',
                                  credit_amount: amount,
                                  debit_amount: 0,
                                  double_entry_description: `${accountType} transaction`,
                              },
                              {
                                  credit_account: '',
                                  debit_account: selectedAccountName,
                                  debit_account_code: selectedAccountCode,
                                  credit_amount: 0,
                                  debit_amount: amount,
                                  double_entry_description: `${accountType} transaction from ${selectedUser}`,
                              },
                          ],
                      };

                      await addDoc(collection(db, 'Journal'), journalData);
                      console.log('Journal entry added:', journalData);

                      const element = document.getElementById('receipt');

                      // Generate PNG image from the receipt element
                      const canvas = await html2canvas(element, { scale: 2, useCORS: true });
                      const imgData = canvas.toDataURL('image/png');
                      const blob = await fetch(imgData).then((res) => res.blob());

                      const storageRef = ref(storage, `Payslip/${id}/receipt.png`);
                      await uploadBytes(storageRef, blob);
                      const downloadURL = await getDownloadURL(storageRef);

                      // Generate PDF from the receipt element
                      const pdf = new jsPDF('p', 'mm', 'a4');
                      const imgProps = pdf.getImageProperties(imgData);
                      const pdfWidth = pdf.internal.pageSize.getWidth();
                      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                      const pdfBlob = pdf.output('blob');

                      const pdfStorageRef = ref(storage, `PayslipPdf/${id}/receipt.pdf`);
                      await uploadBytes(pdfStorageRef, pdfBlob);
                      const pdfDownloadURL = await getDownloadURL(pdfStorageRef);

                      toggle();

                      const transactionData = {
                        approvalStatus: 'Approved',
                        transactionAmount: amount,
                        transactionDate: serverTimestamp(),
                        transactionType: 'Deposit',
                        transactionUser: userRef,
                        transactionId:id,
                        voucherImage:downloadURL,
                        voucherPdf:pdfDownloadURL
                        // loanId: loanIdFieldValue, // Add the loanId field to the transaction data
                      };
                      // Add the transaction data to the collection
                      await addDoc(collection(db, 'allTransaction2'), transactionData);
                  } catch (error) {
                      console.error('Error adding journal entry:', error);
                  }
              }
          } else {
              console.log(`No loan accounts found for user ${firstName}`);
          }
  } catch (error) {
      console.error('Error in handleLoanAccountUpdate:', error);
  }
};


const handleSubmit = async (e) => {
  try {
    e.preventDefault();

    // Use SweetAlert to confirm the action
    const result = await Swal.fire({
      title: 'Confirm',
      text: `Make a payment of TSH ${amount?.toLocaleString()} to ${firstName}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      setLoading(true);

      const amountValue = parseFloat(amount);
      if (isNaN(amountValue) || amountValue <= 0) {
        console.error('Invalid amount:', amount);
        setLoading(false); // Stop loading if there's an error
        return;
      }

      if (!selectedUser) {
        console.error('Selected user is required');
        setLoading(false); // Stop loading if there's an error
        return;
      }

      if (!selectedAccount) {
        console.error('Selected account is required');
        setLoading(false); // Stop loading if there's an error
        return;
      }

      await handleAccountUpdate(selectedAccount, amountValue, selectedUser);

      toast.success(
        <div>
          <i className="fa fa-check" aria-hidden="true" style={{fontSize: '1.5rem'}}></i>
          &nbsp;&nbsp; Deposit Completed Successfully
        </div>
      );

      setSelectedAccount('');
      setAmount('');
      setLoading(false);

      Swal.fire({
        icon: 'success',
        title: 'Deposit Successful',
        text: 'The transaction has been successfully completed.',
        confirmButtonColor: '#28a745',
      });
    }
  } catch (error) {
    console.error('Error in handleSubmit:', error);
    setLoading(false); // Stop loading if there's an error
  }
};


const columns = [
  {
    title: "#",
    dataIndex: "index",
    key: "index",
    align: 'center',
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    align: 'center',
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    align: 'center',
  },
  {
    title: "Unit Price",
    dataIndex: "unitPrice",
    key: "unitPrice",
    align: 'center',
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    align: 'center',
  },
];

const dataSource = [
  {
    key: "1",
    index: 1,
    description: 'Deposit transaction',
    quantity: 1,
    unitPrice: `${numberWithCommas(amount)}`,
    amount: `${numberWithCommas(amount)}`,
    align: 'center',
  },
];

const totalRow = {

  key: 'total',
  description: '',
  quantity: '',
  unitPrice: <b>Total</b>,
  amount:`${numberWithCommas(amount)}`,
  isTotalRow: true, // Add a flag to identify the total row
};

const tableData = [...dataSource, totalRow];

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
     
  return (
    <div>
      <h4 className="mb-lg">Submit Deposit transaction</h4>
      <Row>
        <Col xs={8}>
          <WidgetShadow>
            <Form onSubmit={receipt}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="destinationAccount">Deposit Account:</Label>
                    <Select
                      id="destinationAccount"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select a user"
                      optionFilterProp="children"
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e.target.value)}
                    >
                      {renderUserOptions()}
                    </Select>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="sourceAccount">Destination Account:</Label>
                    <Select
                      id="sourceAccount"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select an account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => setSelectedAccount(value)}
                    >
                      {accountOptionsMemo}
                    </Select>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="amount">Amount</Label>
                <Input
                  id="amount"
                  required
                  name="amount"
                  placeholder="Transfer Amount"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="amount">Date</Label>
                <Input
                  id="amount"
                  required
                  name="amount"
                  placeholder="Transfer Amount"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </FormGroup>
              <TextButton label='Submit' isSubmit={true}/>
            </Form>
            <Receipt
                fetchId='receipt'
                ref={componentRef}
                isOpen={modal}
                toggle={toggle}
                dataSource={tableData}
                columns={columns}
                user={firstName}
                userLocation={userDetails?.location}
                userContacts={userDetails?.phone_number}
                referenceNumber={userDetails?.refNo}
                userDate={date}
                amountRequested={amount}
                submit={handleSubmit}
                receiptTitle="Payment Voucher"
                destination='To'
             />
          </WidgetShadow>
        </Col>
      </Row>
    </div>
  );
};

export default DepositsFormPayment;


