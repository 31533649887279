// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import firebase from 'firebase/compat/app';
import { getFunctions } from "firebase/functions";


const firebaseConfig = {
  apiKey: "AIzaSyDy-TEcoV9z9Ly2JNxBH2j01jj_Xi4cCiQ",
  authDomain: "dspsinglemode.firebaseapp.com",
  projectId: "dspsinglemode",
  storageBucket: "dspsinglemode.appspot.com",
  messagingSenderId: "341721531146",
  appId: "1:341721531146:web:8c285a4ff024cc809b70f3",
  measurementId: "G-FW3V19SZST"
};


export const fetchFirebaseConfig = () => {
  // Initialize Firebase
  const app = firebase.initializeApp(firebaseConfig);
  //const app = initializeApp(firebaseConfig);
  const functions = getFunctions(app);
  const auth = getAuth();
  const db = getFirestore(app);
  const storage = getStorage(app);

  return { app, auth, db, storage,functions };
};

export default fetchFirebaseConfig;