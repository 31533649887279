import React from 'react';
import { Tooltip } from 'antd';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

// ButtonIcon component
const ButtonIcon = ({ icon, onClick, borderRadius = true, disabled = false, style = {}, tooltipTitle }) => {
  const iconButtonStyles = {
    borderRadius: borderRadius ? '10px' : '0px',
    ...style,
  };

  const button = (
    <Button outline color='success' style={iconButtonStyles} onClick={onClick} disabled={disabled}>
      <FontAwesomeIcon icon={icon} />
    </Button>
  );

  return tooltipTitle ? (
    <Tooltip placement="top" title={tooltipTitle}>
      {button}
    </Tooltip>
  ) : button;
};

ButtonIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  borderRadius: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  tooltipTitle: PropTypes.string,
};

// TextButton component
const TextButton = ({ label, onClick, disabled = false, style = {}, isSubmit = false, ...props }) => (
  <Button
    color='success'
    onClick={onClick}
    type={isSubmit ? 'submit' : 'button'}
    style={{ ...style }}
    disabled={disabled}
    {...props}
  >
    <span style={{ color: 'white' }}>{label}</span>
  </Button>
);

TextButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  isSubmit: PropTypes.bool,
};

export { ButtonIcon, TextButton };

