import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import { Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where} from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'firebase/compat/auth';
import { generateExcelFile } from '../../components/ExcelDownload/excelSheet';
import { faPrint, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';


const ChapChapAccounts = () => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [loadingData, setLoadingData] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [printing, setPrinting] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const history = useHistory();
  
    const componentRef = useRef(null);
  
    const { db } = fetchFirebaseConfig();
  
    const fetchLoansAccounts = async () => {
      try {
        setLoadingData(true);
        const querySnapshot = await getDocs(query(collection(db, 'Assets'), where('loanType', '==', 'Chap Chap')));
        const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        setData(fetchedData);
        setLoadingData(false);
      } catch (error) {
        setError(error);
        setLoadingData(false);
      }
    };
  
    useEffect(() => {
      fetchLoansAccounts();
    }, []);
  
    useEffect(() => {
      fetchLoansAccounts();
    }, []);
  
  
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
  
    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };
  
    const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button type="link" size="small" onClick={close}>
              Close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
      onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: text =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });
  
    const renderDateRequested = (timestamp) => {
      if (timestamp && timestamp.seconds) {
        const date = new Date(timestamp.seconds * 1000);
        const formattedDate = date.toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
        return formattedDate;
      }
      return null;
    };
  
    const formatNumber = number => {
      return number?.toLocaleString();
    };
  
  
    const handleFilter = async () => {
      setLoadingData(true);
      try {
        const querySnapshot = await getDocs(query(collection(db, 'Assets'),
          where('date', '>=', new Date(startDate)),
          where('date', '<=', new Date(endDate))
        ));
        const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        setData(fetchedData);
        setLoadingData(false);
      } catch (error) {
        setError(error);
        setLoadingData(false);
      }
    };
  
  
    const handleRedo = () => {
      fetchLoansAccounts();
      setEndDate('');
      setStartDate('');
    };


    const generateExcel = async () => {
      const columns = [
        { header: '#', key: 'id', width: 5 },
        { header: 'Name', key: 'account_name', width: 30 },
        { header: 'Long Term Balance', key: 'balances', width: 20 },
        { header: 'Date Created', key: 'createDate', width: 15 },
      ];
    
     const formatters = {
      id: (_, __, index) => index + 1,
      createDate: renderDateRequested,
    };
  
   await generateExcelFile({
      sheetName: 'Mid Term Accounts',
      title: 'Mid Term Accounts',
      columns: columns,
      data: data,
      formatters: formatters,
      fileName: 'MidTermAccounts.xlsx',
      numericalColumns: ['balances'],
      useFullName: true, // This will use the full name formatter
      useLatestBalance: true, // This will use the latest balance formatter
      customStyles: {
        headerFill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF5F7FA' }
        }
      }
    });
  };
  
  
    const columns = [
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (_, __, index) => index + 1,
      },
      {
        title: 'Name',
        dataIndex: 'account_name',
        key: 'account_name',
        align: 'center',
        ...getColumnSearchProps('account_name'),
        render: (text, record) => (
          <a href={`#/app/accounting/parent-account-ledger/${record.id}`}>
              {text}
            </a>
          ),
      },
      {
        title: 'Chap Chap Balance',
        dataIndex: 'balances',
        key: 'balances',
        align: 'center',
        render: (balances) => {
          if (Array.isArray(balances)) {
            // Find the balance with the latest timestamp
            const latestBalance = balances.reduce((prev, current) => {
              return (new Date(prev.date) > new Date(current.date)) ? prev : current;
            });
            return formatNumber(latestBalance.balance);
          }
          return 'N/A'; // Or any default value or message
       }
      },
      {
        title: 'Date Created',
        dataIndex: 'createDate',
        key: 'CreateDate',
        align: 'center',
        render: renderDateRequested,
      },
      {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <Space>
           <ButtonIcon icon={faUserShield} tooltipTitle="Panel" onClick={() => history.push(`/app/loans/loans-panel/${record.id}`)}/>
          </Space>
        ),
      },
    ];
  
    return (
      <div>
        <h5 className="mb-1">Chap Chap Accounts</h5>
        <Widget
         title={<h7><span className="fw-semi-bold">Data Range</span></h7>}
        >
           <Row  style={{marginTop:'5px'}}>
             <Col md={6}>
                      <FormGroup>
                        <Label for="exampleFirstName">Start Date</Label>
                        <InputData
                          id="exampleFirstName"
                          name="startDate"
                          placeholder="Start Date"
                          type="date"
                          bsSize="sm"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleMiddleName">End Date</Label>
                        <InputData
                          id="endDate"
                          name="middlename"
                          placeholder="End date"
                          type="date"
                          bsSize="sm"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                   <TextButton label='Search'  onClick={handleFilter} size='sm'/>
                    <div style={{ marginLeft: '10px' }}></div>
                    <TextButton label='Reset'  onClick={handleRedo} size='sm'/>
                  </div>
             </Widget>
        <Widget>
          <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonGroup size='sm'>
            <TextButton label='PDF'  size='sm'/>
            <TextButton label='CSV' onClick={generateExcel} size='sm'/>
              <ReactToPrint
                trigger={() => (
                <ButtonIcon icon={faPrint} size="sm" disabled={printing} borderRadius={false}/>
                )}
                content={() => componentRef.current} // Specify the component to be printed
                onBeforeGetContent={() => setPrinting(true)}
                onAfterPrint={() => setPrinting(false)}
              />
            </ButtonGroup>
          </div>
          <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
            <Table columns={columns} dataSource={data} loading={loadingData} size="small" />
          </div>
        </Widget>
      </div>
    );
  };
  
  export default ChapChapAccounts;
