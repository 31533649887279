import React, { useRef, useState, useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import { Button as Click, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Breadcrumb, BreadcrumbItem,Form,Col,Row } from 'reactstrap';
import { Button, Space, Table, Spin } from 'antd'; // Imported Spin for loading indicator
import { SearchOutlined } from '@ant-design/icons';
import { getDocs, collection} from 'firebase/firestore'; // Added deleteDoc and doc
import { fetchFirebaseConfig } from '../../../firebase';
import { useParams,useHistory} from "react-router-dom";
import Widget from '../../../components/Widget/Widget';
import { getDoc, doc } from 'firebase/firestore';

const GrandChildAccountLedger = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [ledgerData, setLedgerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [accountOptions, setAccountOptions] = useState([]);
  const [accountName, setAccountName] = useState('');
  const [accountType, setAccountType] = useState('');
  const searchInput = useRef(null);
  const { db } = fetchFirebaseConfig();

  const { id } = useParams(); // Get the UID from the URL

  useEffect(() => {
    const fetchAccountName = async () => {
      try {
        const collections = ['Assets', 'Income', 'Equity', 'Expenses', 'Liabilities'];
        let found = false;
        
        for (const collectionName of collections) {
          const userDocRef = doc(db, collectionName, id);
          const userDocSnapshot = await getDoc(userDocRef);
    
          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            console.log('Fetched user data:', userData);
            setAccountName(userData.account_name || '');
            setAccountType(collectionName); // Set the account type based on the collection name
            found = true;
            break; // Stop the loop once the user is found
          } else {
            console.log("User not found in collection:", collectionName);
          }
        }
    
        if (!found) {
          // Handle the case where the account name is not found
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }; 
    fetchAccountName();
  }, [id, db]);

  const fetchLedgerData = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'Journal'));
      const data = querySnapshot.docs
        .map((doc) => doc.data())
        .filter((doc) => {
          // Check if the accountName is in any of the document's entries
          return doc.entries.some(entry => 
            entry.debit_account === accountName || entry.credit_account === accountName
          );
        });
      setLedgerData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching ledger data: ', error);
      setLoading(false);
    }
  };
  
  
  useEffect(() => {
    fetchLedgerData();
  }, [accountName, db]);
   

  const handleRedo = () => {
    setEndDate('');
    setStartDate('');
  };

  useEffect(() => {
    const fetchAccountNames = async () => {
      try {
        const assetsSnapshot = await getDocs(collection(db, 'Assets'));
        const liabilitiesSnapshot = await getDocs(collection(db, 'Liabilities'));
        const expensesSnapshot = await getDocs(collection(db, 'Expenses'));
        const incomeSnapshot = await getDocs(collection(db, 'Income'));
        const equitySnapshot = await getDocs(collection(db, 'Equity'));

        const assetsNames = assetsSnapshot.docs.map((doc) => doc.data().account_name);
        const liabilitiesNames = liabilitiesSnapshot.docs.map((doc) => doc.data().account_name);
        const expensesNames = expensesSnapshot.docs.map((doc) => doc.data().account_name);
        const incomeNames = incomeSnapshot.docs.map((doc) => doc.data().account_name);
        const equityNames = equitySnapshot.docs.map((doc) => doc.data().account_name);

        const allAccountNames = [...assetsNames, ...liabilitiesNames, ...expensesNames, ...incomeNames, ...equityNames];
        setAccountOptions([...new Set(allAccountNames)]);
      } catch (error) {
        console.error('Error fetching account names: ', error);
      }
    };

    fetchAccountNames();
  }, [db]);

  const handleFilter = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'Journal'));
      const data = querySnapshot.docs
        .map((doc) => doc.data())
        .filter((entry) => {
          const entryDate = new Date(entry.date);
          const start = startDate ? new Date(startDate) : null;
          const end = endDate ? new Date(endDate) : null;

          if (start && entryDate < start) {
            return false;
          }

          if (end && entryDate > end) {
            return false;
          }

          // Check if the selected account matches the entry's account
          if (selectedAccount && entry.entries.every((entryItem) => entryItem.debit_account !== selectedAccount && entryItem.credit_account !== selectedAccount)) {
            return false;
          }

          return true;
        });

      setLedgerData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching ledger data: ', error);
      setLoading(false);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      ...getColumnSearchProps('date'),
    },
    {
      title: 'Code',
      dataIndex: 'journalCode',
      key: 'journalCode',
      align: 'center',
      ...getColumnSearchProps('journalCode'),
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      align: 'center',
      ...getColumnSearchProps('transactionType'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      ...getColumnSearchProps('description'),
    },
    {
      title: 'Account Name',
      dataIndex: 'destinationAccounts',
      key: 'destinationAccounts',
      align: 'center',
      ...getColumnSearchProps('destinationAccounts'),
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      key: 'debit',
      align: 'center',
      ...getColumnSearchProps('debit'),
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
      align: 'center',
      ...getColumnSearchProps('credit'),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      align: 'center',
      ...getColumnSearchProps('balance'),
    },
  ];

  const formatValue = (value) => {
    return value !== undefined && value !== 0 ? value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '--';
  };

  const formatDebitCredit = (amount, accountType, isDebit) => {
    if (accountType === 'Assets') {
      // For Assets: Debit is positive, Credit is negative
      return isDebit ? amount : -amount;
    } else if (accountType === 'Liabilities' || accountType === 'Equity' || accountType === 'Income') {
      // For Liabilities, Equity, and Income: Debit is negative, Credit is positive
      return isDebit ? -amount : amount;
    } else if (accountType === 'Expenses') {
      // For Expenses: Debit is positive, Credit is negative
      return isDebit ? amount : -amount;
    } else {
      return amount;
    }
  };
  
  const calculateBalance = (data) => {
    let balance = 0;
    return data.map((entry) => {
      const debit = entry.debit !== '--' ? parseFloat(entry.debit.replace(/,/g, '')) : 0;
      const credit = entry.credit !== '--' ? parseFloat(entry.credit.replace(/,/g, '')) : 0;
      balance += debit + credit;
      return {
        ...entry,
        balance: formatValue(balance)
      };
    });
  };
  
  const formattedLedgerData = ledgerData.reduce((acc, entry) => {
    const debitAccounts = [];
    const creditAccounts = [];
  
    entry.entries.forEach((entryItem) => {
      const isDebit = entryItem.debit_account === accountName;
      const isCredit = entryItem.credit_account === accountName;
  
      if (!isDebit) {
        debitAccounts.push(entryItem.debit_account);
      }
  
      if (!isCredit) {
        creditAccounts.push(entryItem.credit_account);
      }
    });
  
    // Concatenate and remove duplicates
    const allAccounts = [...debitAccounts, ...creditAccounts];
    const uniqueAccounts = [...new Set(allAccounts)];
  
    // Filter destination accounts based on opposite category of accountName
    const destinationAccounts = uniqueAccounts.filter(account => {
      if (entry.entries.some(entryItem => entryItem.debit_account === accountName)) {
        return entry.entries.some(entryItem => entryItem.credit_account === account);
      } else if (entry.entries.some(entryItem => entryItem.credit_account === accountName)) {
        return entry.entries.some(entryItem => entryItem.debit_account === account);
      }
      return false;
    }).map(account => (
      <li key={account}>{account}</li>
    ));
  
    const debitAmount = entry.entries
      .filter((entryItem) => entryItem.debit_account === accountName)
      .reduce((total, entryItem) => total + parseFloat(entryItem.debit_amount), 0);
  
    const creditAmount = entry.entries
      .filter((entryItem) => entryItem.credit_account === accountName)
      .reduce((total, entryItem) => total + parseFloat(entryItem.credit_amount), 0);
  
    const formattedDebitAmount = formatDebitCredit(debitAmount, accountType, true);
    const formattedCreditAmount = formatDebitCredit(creditAmount, accountType, false);
  
    const debit = formatValue(formattedDebitAmount);
    const credit = formatValue(formattedCreditAmount);
    const balance = formatValue(formattedDebitAmount - formattedCreditAmount);
  
    const rowData = {
      id: acc.length + 1,
      date: entry.date,
      description: entry.description,
      transactionType: entry.transactionType,
      debit,
      credit,
      balance,
      journalCode: entry.journalCode,
      destinationAccounts,
    };
  
    acc.push(rowData);
  
    return acc;
  }, []);
  
  // Calculate the running balance for each row
  const ledgerDataWithBalance = calculateBalance(formattedLedgerData);
  
  
 
  return (
    <div>
      {/* <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Ledger</BreadcrumbItem>
      </Breadcrumb> */}
      <h4 className="mb-lg">{accountName}</h4>
      <Widget
       title={<h7><span className="fw-semi-bold">Data Range</span></h7>}
      >
         <Row  style={{marginTop:'15px'}}>
           <Col md={6}>
                    <FormGroup>
                      <Label for="exampleFirstName">Start Date</Label>
                      <Input
                        id="exampleFirstName"
                        name="startDate"
                        placeholder="Start Date"
                        type="date"
                        bsSize="sm"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleMiddleName">End Date</Label>
                      <Input
                        id="endDate"
                        name="middlename"
                        placeholder="End date"
                        type="date"
                        bsSize="sm"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Click type='submit' size='sm' color='success'>Search</Click>
                  <div style={{ marginLeft: '10px' }}></div>
                  <Click size='sm' color='success' onClick={handleRedo}>Reset</Click>
                </div>
           </Widget>
      <Widget>
      <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
      <Table columns={columns} dataSource={ledgerDataWithBalance} loading={loading} size='small' />
      </div>
      </Widget>
    </div>
  );
};

export default GrandChildAccountLedger;
