import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import {fetchFirebaseConfig} from '../../../firebase';
import { getDocs, collection, query, where, updateDoc } from 'firebase/firestore';
import { ref, getStorage, uploadBytes, getDownloadURL } from 'firebase/storage';
import Widget from '../../../components/Widget/Widget';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WidgetShadow from '../../../components/WidgetShadow/WidgetShadow';
import { TextButton } from '../../../components/Buttons/CustomButtons';

const Settings = () => {
  const { db, storage } = fetchFirebaseConfig();

  const [logoImage, setLogoImage] = useState('');
  const [institutionName, setInstitutionName] = useState('');
  const [referenceCode, setReferenceCode] = useState('');

  useEffect(() => {
    const fetchCompanyLogo = async () => {
      try {
        const companyLogoQuery = query(collection(db, 'company_logo'));
        const companyLogoSnapshot = await getDocs(companyLogoQuery);
        if (!companyLogoSnapshot.empty) {
          const docData = companyLogoSnapshot.docs[0].data();
          setReferenceCode(docData.referenceCode || '');
          setInstitutionName(docData.institutionName || '');
          setLogoImage(docData.path || '');
        }
      } catch (error) {
        console.error('Error fetching company logo:', error);
      }
    };

    fetchCompanyLogo();
  }, [db]);

  const handleLogoImageChange = async (event) => {
    const selectedImage = event.target.files[0];
    try {
      // Upload image file to Firebase Storage
      const storageRef = ref(storage, `logo/${selectedImage.name}`);
      await uploadBytes(storageRef, selectedImage);

      // Get download URL of the uploaded image
      const logoImageUrl = await getDownloadURL(storageRef);

      // Set the downloaded URL as the logo image source
      setLogoImage(logoImageUrl);
    } catch (error) {
      console.error('Error uploading logo image:', error);
      toast.error('Error uploading logo image!');
    }
  };

  const handleInstitutionNameChange = (event) => {
    setInstitutionName(event.target.value);
  };

  const handleReferenceCodeChange = (event) => {
    setReferenceCode(event.target.value);
  };

  const handleLogoUpdate = async () => {
    if (logoImage && institutionName) {
      try {
        // Update logo and institution name
        const q = query(collection(db, 'company_logo'), where('reference', '==', 'NCAAWS'));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          await updateDoc(doc.ref, {
            path: logoImage,
            institutionName: institutionName,
            referenceCode:referenceCode,
            updateDate: new Date().toISOString(),
          });
        });
        toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Info updated successfully</div>); 
      } catch (error) {
        console.error('Error updating logo and institution name:', error);
        toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Error updating Info</div>);;
      }
    } else {
      toast.warning(<div><i className="fa fa-exclamation-triangle" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp;Please select a logo image and enter an institution name</div>);
    }
  };


  return (
    <div>
      <Row>
        <Col xs={12}>
          <WidgetShadow>
            <Form>
                  <div style={{ marginBottom: '15px' }} className="text-center d-flex align-items-center justify-content-center">
                    <img
                      className="profileImg"
                      src={logoImage}
                      style={{ padding: '25px', borderRadius: '50%' }}
                      width="150px"
                      height="150px"
                      alt="Profile Preview"
                    />
                  </div>
                  <FormGroup className="mb-3">
                    <Label>Update Logo</Label>
                    <Input
                      type="file"
                      id="logoInput"
                      accept="image/*"
                      style={{ fontSize: '13px' }}
                      onChange={handleLogoImageChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="institutionName">
                      Institution Name
                    </Label>
                    <Input
                      id="institutionName"
                      name="institutionName"
                      placeholder="Institution name"
                      type="text"
                      value={institutionName}
                      onChange={handleInstitutionNameChange}
                    />
                  </FormGroup>
              <FormGroup>
                <Label for="InstitutionCode">
                  Institution Code
                </Label>
                <Input
                  id="InstitutionCode"
                  name="InstitutionCode"
                  placeholder="Institution Code"
                  type='text'
                  value={referenceCode}
                  onChange={handleReferenceCodeChange}
                />
              </FormGroup>
              <TextButton label='Edit'  onClick={handleLogoUpdate}/>
            </Form>
          </WidgetShadow>
        </Col>
      </Row>
      {/* <ToastContainer position="top-right" autoClose={4000} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover style={{ fontSize:'13.5px'}}/> */}
    </div>
  );
};

export default Settings;