import { createUserWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, setDoc, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { sendEmailVerification } from 'firebase/auth';
import {
  serverTimestamp,
  collection,
  where,
  query,
  getDocs,
} from 'firebase/firestore';
import { deleteApp } from 'firebase/app';
import { fetchFirebaseConfig } from '../../firebase';
// Function to create a user and upload their profile
export const createUserAndUploadProfile = async (
  values,
  auth,
  db,
  app,
  storage
) => {
  try {
    // Destructure values
    const {
      Role,
      email,
      display_name,
      mid_name,
      sir_name,
      password,
      position,
      gender,
      loanCommitee,
      selectedPhoto,
      religion,
      refNo,
      accountName,
      accountNo,
      dob,
      refFirstName,
      refMiddleName,
      refSirName,
      refemail,
      refcontact,
      refgender,
      refrelationshipStatus,
      heirFirstName,
      heirMiddleName,
      heirSirName,
      heiremail,
      heircontact,
      heirgender,
      heirrelationshipStatus,
      ...userData
    } = values;

    // Create the user in Firebase Authentication
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    // Upload the selected photo file to Firebase Storage
    let photo_url = '';
    if (selectedPhoto) {
      const storageRef = ref(storage, `users/${user.uid}`);
      await uploadBytes(storageRef, selectedPhoto);
      photo_url = await getDownloadURL(storageRef);
    }

    const { db } = fetchFirebaseConfig();

    const generateAccountCode = () => {
      // Generate a random three-digit number and concatenate with "0"
      const randomDigits = Math.floor(Math.random() * 900) + 100; // Generates a number between 100 and 999
      return `${randomDigits}0`;
    };

    const Code = `${generateAccountCode()}`;

    //Create the user data in Firestore
    const userRef = doc(db, 'users', user.uid);
    await setDoc(userRef, {
      uid: user.uid,
      Role: 'Admin',
      email,
      display_name,
      mid_name,
      sir_name,
      password,
      position: 'Member',
      gender,
      loanCommitee: false,
      religion,
      refNo,
      accountName,
      accountNo,
      dob,
      refFirstName,
      refMiddleName,
      refSirName,
      refemail,
      refcontact,
      refgender,
      refrelationshipStatus,
      heirFirstName,
      heirMiddleName,
      heirSirName,
      heiremail,
      heircontact,
      heirgender,
      heirrelationshipStatus,
      ...userData,
      loanLimit: 0,
      created_time: serverTimestamp(),
      photo_url: photo_url,
      // Role, // Use "Role" instead of "role"
    });

    const formatName = (display_name, mid_name, sir_name) => {
      const capitalize = (str) =>
        str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      const firstName = display_name ? capitalize(display_name) : '';
      const middleInitial = mid_name
        ? mid_name.charAt(0).toUpperCase() + '.'
        : '';
      const lastName = sir_name ? capitalize(sir_name) : '';
      return `${firstName} ${middleInitial} ${lastName}`.trim();
    };

    const formattedAccountName = formatName(display_name, mid_name, sir_name);

    // Create loan document when the user is created in Loans collection
    const loansRef = collection(db, 'Loans');
    const loanDocRef = doc(loansRef);
    await setDoc(loanDocRef, {
      user: userRef,
      loanAmount: 0,
      loanDuration: '',
      loanLimit: 0, // Set the loanLimit field to 0
      loanSchedual: '',
      dateTaken: serverTimestamp(),
    });

    const fetchParentData = async (accountName) => {
      const q = query(
        collection(db, 'Assets'),
        where('account_name', '==', accountName)
      );
      const snapshot = await getDocs(q);
      const data = snapshot.docs[0]?.data();
      return data || {};
    };

    //Loan Accounts
    const parentDataShortTerm = await fetchParentData('Mikopo ya muda mfupi');
    const parentDataChapChap = await fetchParentData('Mikopo ya Chap Chap');
    const parentDataLongTerm = await fetchParentData('Mikopo ya muda mrefu');

    // Create a new Date object from the individual date parts
    const dateParts = new Date()
      .toLocaleString()
      .split(',')[0]
      .split('/');
    const year = parseInt(dateParts[2]);
    const month = parseInt(dateParts[0]) - 1; // Months are zero-indexed
    const day = parseInt(dateParts[1]);

    // Extract the time from the current date object
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();

    // Create a new Date object with the combined date and time parts
    const serverTimestampmap = new Date(
      year,
      month,
      day,
      hours,
      minutes,
      seconds
    );

    const createLoanAccount = async (parentAccount, loanType, parentData) => {
      if (!parentData.documentId) {
        console.error('parentData.documentId is undefined or missing');
        return;
      }

      const loansAccountsRef = collection(db, 'Assets');
      await addDoc(loansAccountsRef, {
        user: userRef,
        account_code: generateAccountCode(),
        account_name: formattedAccountName,
        balances: [
          {
            balance: 0,
            date: serverTimestampmap, // Use the formatDate function to format the date
            creditBalance: 0,
            debitBalance: 0,
          },
        ],
        account_level: 'grandchild',
        account_type: 'current-assets',
        parentAccount,
        loanType,
        IsMainAccount: 'No',
        createDate: serverTimestamp(),
        parentAccountId: parentData.documentId,
        grandParentAccount: parentData.parentAccount,
        grandParentId: parentData.parentAccountId,
      });
    };

    await createLoanAccount(
      'Mikopo ya muda mfupi',
      'Development Fund',
      parentDataShortTerm
    );
    await createLoanAccount(
      'Mikopo ya Chap Chap',
      'Chap Chap',
      parentDataChapChap
    );
    await createLoanAccount(
      'Mikopo ya muda mrefu',
      'Long Term Loan',
      parentDataLongTerm
    );

    //Savings Account
    const fetchSavingsParentData = async (accountName) => {
      const q = query(
        collection(db, 'Liabilities'),
        where('account_name', '==', accountName)
      );
      const snapshot = await getDocs(q);
      const data = snapshot.docs[0]?.data();
      return data || {};
    };

    const parentDataSavings = await fetchSavingsParentData(
      'Akiba za wanachama'
    );

    const createSavingsAccount = async (
      parentAccount,
      AccountType,
      parentData
    ) => {
      console.log('parentAccount:', parentAccount);
      console.log('parentData:', parentData);

      if (!parentData.documentId) {
        console.error('parentData.documentId is undefined or missing');
        return;
      }

      const savingsAccountsRef = collection(db, 'Liabilities');
      await addDoc(savingsAccountsRef, {
        user: userRef,
        account_code: generateAccountCode(),
        account_name: formattedAccountName,
        balances: [
          {
            balance: 0,
            date: serverTimestampmap, // Use the formatDate function to format the date
            creditBalance: 0,
            debitBalance: 0,
          },
        ],
        account_level: 'grandchild',
        account_type: 'long term liabilities',
        parentAccount,
        AccountType,
        IsMainAccount: 'No',
        createDate: serverTimestamp(),
        parentAccountId: parentData.documentId,
        grandParentAccount: parentData.parentAccount,
        grandParentId: parentData.parentAccountId,
      });
    };

    await createSavingsAccount(
      'Akiba za wanachama',
      'Savings Account',
      parentDataSavings
    );


    
     //Shares Account
        const fetchSharesParentData = async (accountName) => {
          const q = query(
            collection(db, 'Liabilities'),
            where('account_name', '==', accountName)
          );
          const snapshot = await getDocs(q);
          const data = snapshot.docs[0]?.data();
          return data || {};
        };
    
        const parentDataShares = await fetchSharesParentData('Hisa za wanachama');
    
        const createSharesAccount = async (parentAccount,AccountType,parentData) => {
          console.log('parentAccount:', parentAccount);
          console.log('parentData:', parentData);
    
          if (!parentData.documentId) {
            console.error('parentData.documentId is undefined or missing');
            return;
          }
    
          const sharesAccountsRef = collection(db, 'Liabilities');
          await addDoc(sharesAccountsRef, {
            user: userRef,
            account_code: generateAccountCode(),
            account_name: formattedAccountName,
            balances: [
              {
                balance: 0,
                date: serverTimestampmap, // Use the formatDate function to format the date
                creditBalance: 0,
                debitBalance: 0,
              },
            ],
            account_level: 'grandchild',
            account_type: 'long term liabilities',
            parentAccount,
            AccountType,
            IsMainAccount: 'No',
            createDate: serverTimestamp(),
            parentAccountId: parentData.documentId,
            grandParentAccount: parentData.parentAccount,
            grandParentId: parentData.parentAccountId,
          });
        };
    
        await createSharesAccount(
          'Hisa za wanachama',
          'Shares Account',
          parentDataShares
       );


            //Deposits Account
            const fetchDepositsParentData = async (accountName) => {
              const q = query(
                collection(db, 'Liabilities'),
                where('account_name', '==', accountName)
              );
              const snapshot = await getDocs(q);
              const data = snapshot.docs[0]?.data();
              return data || {};
            };
        
            const parentDataDeposits = await fetchDepositsParentData('Matoleo');
        
            const createDepositsAccount = async (parentAccount,AccountType,parentData) => {
              console.log('parentAccount:', parentAccount);
              console.log('parentData:', parentData);
        
              if (!parentData.documentId) {
                console.error('parentData.documentId is undefined or missing');
                return;
              }
        
              const depositsAccountsRef = collection(db, 'Liabilities');
              await addDoc(depositsAccountsRef, {
                user: userRef,
                account_code: generateAccountCode(),
                account_name: formattedAccountName,
                balances: [
                  {
                    balance: 0,
                    date: serverTimestampmap, // Use the formatDate function to format the date
                    creditBalance: 0,
                    debitBalance: 0,
                  },
                ],
                account_level: 'grandchild',
                account_type: 'long term liabilities',
                parentAccount,
                AccountType,
                IsMainAccount: 'No',
                createDate: serverTimestamp(),
                parentAccountId: parentData.documentId,
                grandParentAccount: parentData.parentAccount,
                grandParentId: parentData.parentAccountId,
              });
            };
        
            await createDepositsAccount(
              'Matoleo ya wanachama',
              'Deposit Account',
              parentDataDeposits
           );

    // Send email verification to the user
    await sendEmailVerification(auth.currentUser);

    await signOut(auth);

    await deleteApp(app);

    // Return success
    return true;
  } catch (error) {
    console.log(error);
    // Return failure
    return false;
  }
};
