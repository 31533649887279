import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import Widget from '../../components/Widget/Widget';
import { useParams } from "react-router-dom";
import { getDocs, collection, query, where, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';  

const LedgerAccount = () => {
  const { id } = useParams();
  const { db } = fetchFirebaseConfig();
  const [accountName, setAccountName] = useState('');
  const [accountCode, setAccountCode] = useState('');
  const [accountType, setAccountType] = useState('');
  const [childAccounts, setChildAccounts] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [journalData, setJournalData] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [currentBalance, setCurrentBalance] = useState(0);

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const collections = ['Assets', 'Income', 'Equity', 'Expenses', 'Liabilities'];
        let found = false;

        for (const collectionName of collections) {
          const userDocRef = doc(db, collectionName, id);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            console.log('Fetched user data:', userData);
            setAccountName(userData.account_name || '');
            setAccountCode(userData.account_code || '');
            setAccountType(collectionName);

            if (userData.balances && userData.balances.length > 0) {
              const latestBalance = userData.balances.reduce((latest, current) => {
                return current.date.toDate() > latest.date.toDate() ? current : latest;
              });
              setCurrentBalance(latestBalance.balance);
            }

            found = true;
            break;
          } else {
            console.log("User not found in collection:", collectionName);
          }
        }

        if (!found) {
          // Handle the case where the account name is not found
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchAccountData();
  }, [id, db]);

  useEffect(() => {
    const fetchAllAccounts = async () => {
      try {
        const collections = ['Assets', 'Income', 'Equity', 'Expenses', 'Liabilities'];
        let allAccountsData = [];

        for (const collectionName of collections) {
          const querySnapshot = await getDocs(collection(db, collectionName));
          querySnapshot.forEach((doc) => {
            allAccountsData.push(doc.data());
          });
        }

        setAllAccounts(allAccountsData);
      } catch (error) {
        console.error("Error fetching all accounts:", error);
      }
    };

    fetchAllAccounts();
  }, [db]);

  useEffect(() => {
    const fetchChildAccounts = async () => {
      setIsLoading(true);
      if (accountName) {
        try {
          const collections = ['Assets', 'Income', 'Equity', 'Expenses', 'Liabilities'];
          let allChildAccounts = [];

          for (const collectionName of collections) {
            const q = query(collection(db, collectionName), where('parentAccount', '==', accountName));
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
              allChildAccounts.push(doc.data());
            });
          }

          setChildAccounts(allChildAccounts);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching child accounts:", error);
        }
      }
    };

    fetchChildAccounts();
  }, [accountName, db]);

  useEffect(() => {
    const fetchJournalData = async () => {
      try {
        const q = query(collection(db, 'Journal'));
        const querySnapshot = await getDocs(q);
        const journals = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          journals.push(data);
        });

        setJournalData(journals);
      } catch (error) {
        console.error("Error fetching journal data:", error);
      }
    };

    fetchJournalData();
  }, [db]);

  const processJournalEntries = (account) => {
    const processedData = [];

    journalData.forEach((journal) => {
      const journalDate = journal.date ? journal.date : serverTimestamp();
      const transaction = journal.transactionType ? journal.transactionType : 'Transfer';

      journal.entries.forEach((entry) => {
        const accountInEntry = (entry.debit_account === account.account_name && entry.debit_account_code === account.account_code) || 
                               (entry.credit_account === account.account_name && entry.credit_account_code === account.account_code);
        const isChildAccount = allAccounts.some(acc => 
          acc.account_name === entry.debit_account && 
          acc.parentAccount === account.account_name && 
          acc.account_code === entry.debit_account_code
        ) || allAccounts.some(acc => 
          acc.account_name === entry.credit_account && 
          acc.parentAccount === account.account_name && 
          acc.account_code === entry.credit_account_code
        );
    
        if (accountInEntry || isChildAccount) {
          const destinationAccounts = new Set();
    
          journal.entries.forEach((innerEntry) => {
            const isDestinationChildAccount = allAccounts.some(acc =>
              (innerEntry.debit_account && acc.account_name === innerEntry.debit_account && acc.parentAccount === account.account_name) ||
              (innerEntry.credit_account && acc.account_name === innerEntry.credit_account && acc.parentAccount === account.account_name)
            );
    
            if (!isDestinationChildAccount && innerEntry.debit_account && innerEntry.debit_account !== account.account_name) {
              destinationAccounts.add(innerEntry.debit_account);
            }
            if (!isDestinationChildAccount && innerEntry.credit_account && innerEntry.credit_account !== account.account_name) {
              destinationAccounts.add(innerEntry.credit_account);
            }
          });
    
          const debit = entry.debit_account === account.account_name ? entry.debit_amount : 0;
          const credit = entry.credit_account === account.account_name ? entry.credit_amount : 0;

          if (debit !== 0 || credit !== 0) {
            processedData.push({
              key: `${account.account_name}-${Math.random()}`, 
              date: journalDate,
              transactionType:transaction,
              accountName: account.account_name,
              description: entry.double_entry_description || 'Entry Transfer',
              destination: Array.from(destinationAccounts).join(', '),
              debit: debit,
              credit: credit,
            });
          }
    
          allAccounts.forEach(childAccount => {
            if ((entry.debit_account === childAccount.account_name || entry.credit_account === childAccount.account_name) && childAccount.parentAccount === account.account_name) {
              const childDebit = entry.debit_account === childAccount.account_name ? entry.debit_amount : 0;
              const childCredit = entry.credit_account === childAccount.account_name ? entry.credit_amount : 0;

              if (childDebit !== 0 || childCredit !== 0) {
                processedData.push({
                  key: `${childAccount.account_name}-${Math.random()}`, 
                  date: journalDate,
                  transactionType:transaction,
                  accountName: childAccount.account_name,
                  description: entry.double_entry_description || 'Entry Transfer',
                  destination: Array.from(destinationAccounts).join(', '),
                  debit: childDebit,
                  credit: childCredit,
                });
              }
            }
          });
        }
      });
    });
    
    return processedData.sort((a, b) => b.date.seconds - a.date.seconds);
  };

  const renderTables = () => {
    const tables = [];

    for (let i = 0; i < childAccounts.length; i++) {
      const accountData = processJournalEntries(childAccounts[i]);

      let totalDebit = 0;
      let totalCredit = 0;
      let totalBalance = 0;

      accountData.forEach((entry) => {
        totalDebit += parseFloat(entry.debit);
        totalCredit += parseFloat(entry.credit);
      });

      let balance = 0;
      if (accountType === 'Assets' || accountType === 'Expenses') {
        balance = totalDebit - totalCredit;
      } else if (['Liabilities', 'Income', 'Equity'].includes(accountType)) {
        balance = totalCredit - totalDebit;
      }

      tables.push(
        <div key={i} style={{ marginBottom: '20px' }}>
          <h5 className="mb-lg">{childAccounts[i].account_code}&nbsp;-&nbsp;<b>{childAccounts[i].account_name}</b></h5>
          <Table 
            columns={columns} 
            dataSource={accountData} 
            pagination={false} 
            loading={loading} 
            size='small'
            footer={() => (
              <div style={{ fontWeight: 'bold', textAlign: 'right' }}>
              <span>Total Debit: {totalDebit.toLocaleString('en-US', { maximumFractionDigits: 2 })}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span>Total Credit: {totalCredit.toLocaleString('en-US', { maximumFractionDigits: 2 })}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span>Total Balance: {balance.toLocaleString('en-US', { maximumFractionDigits: 2 })}</span>
              </div>
            )} 
          />
        </div>
      );
    }

    if (childAccounts.length === 0 && accountName) {
      const accountData = processJournalEntries({ account_name: accountName, account_code: accountCode });
      
      if (accountData.length > 0) {
        let totalDebit = 0;
        let totalCredit = 0;
        let balance = 0;

        accountData.forEach((entry) => {
          totalDebit += parseFloat(entry.debit);
          totalCredit += parseFloat(entry.credit);
        });

        if (accountType === 'Assets' || accountType === 'Expenses') {
          balance = totalDebit - totalCredit;
        } else if (['Liabilities', 'Income', 'Equity'].includes(accountType)) {
          balance = totalCredit - totalDebit;
        }

        tables.push(
          <div key="main-account" style={{ marginBottom: '20px' }}>
            <Table 
              columns={columns} 
              dataSource={accountData} 
              pagination={false} 
              loading={loading} 
              size='small'
              footer={() => (
                <div style={{ fontWeight: 'bold', textAlign: 'right' }}>
                <span>Total Debit: {totalDebit.toLocaleString('en-US', { maximumFractionDigits: 2 })}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>Total Credit: {totalCredit.toLocaleString('en-US', { maximumFractionDigits: 2 })}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>Total Balance: {balance.toLocaleString('en-US', { maximumFractionDigits: 2 })}</span>
                </div>
              )} 
            />
          </div>
        );
      } else {
        tables.push(<p key="no-journal">No entries found for this account.</p>);
      }
    }

    return tables.length > 0 ? tables : <p>No child accounts found.</p>;
  };

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: renderDateRequested,
    },
    {
      title: 'Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
    },
    {
      title: 'Account Name',
      dataIndex: 'accountName',
      key: 'accountName',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Destination Account',
      dataIndex: 'destination',
      key: 'destination',
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      key: 'debit',
      render: (text) => parseFloat(text).toLocaleString('en-US', { maximumFractionDigits: 2 }),
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
      render: (text) => parseFloat(text).toLocaleString('en-US', { maximumFractionDigits: 2 }),
    },    
  ];
  
  return (
    <div>
    <Breadcrumb separator=">">
    <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
    <Breadcrumb.Item>Accounting</Breadcrumb.Item>
    <Breadcrumb.Item>Ledger</Breadcrumb.Item>
    <Breadcrumb.Item>{accountName}&nbsp;&nbsp;legder</Breadcrumb.Item>
  </Breadcrumb>
  <div style={{marginTop:'10px'}}>
    <WidgetShadow
      title={<h4 className="mb-lg">{accountCode}&nbsp;-&nbsp;<b>{accountName}</b></h4>}
    >
       <div style={{ textAlign: 'left', marginTop:'-25px', marginBottom:'30px'}}>
         <div style={{ fontWeight: 'bold',borderBottom: `1px solid rgba(128, 128, 128, 0.03)`, width: '100%', marginBottom: '5px', paddingBottom: '5px', backgroundColor:'#fffff5' }}>
             Total Balance: {currentBalance.toLocaleString()}
          </div>
        </div>

      <div>{renderTables()}</div>
    </WidgetShadow>
    </div>
  </div>
  );
};

export default LedgerAccount;


