import React, { useState } from 'react';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBook, faScaleBalanced, faBank, faHandHoldingDollar, faLayerGroup } from '@fortawesome/free-solid-svg-icons';

const items = [
  {
    key: 'profile',
    icon: <FontAwesomeIcon icon={faUser}/>,
    label: 'Profile Settings',
  },
  {
    key: 'theme',
    icon: <FontAwesomeIcon icon={faLayerGroup}/>,
    label: 'Theme Settings',
  },
  {
    key: 'loan',
    icon: <FontAwesomeIcon icon={faHandHoldingDollar}/>,
    label: 'Loan Settings',
 },
  {
    key: 'app',
    icon: <FontAwesomeIcon icon={faBook}/>,
    label: 'Accounting Settings',
  },
];

const getLevelKeys = (items) => {
  const key = {};
  const func = (items, level = 1) => {
    items.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items);
  return key;
};

const levelKeys = getLevelKeys(items);

const SettingsMenu = ({ onMenuClick }) => {
  const [stateOpenKeys, setStateOpenKeys] = useState(['profile']);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      setStateOpenKeys(openKeys);
    }
  };

  return (
    <>
      <style>
        {`
          .custom-menu {
            height: 100%;
            width: 100%;
            font-size: 13.2px;
          }
          .custom-menu .ant-menu-item,
          .custom-menu .ant-menu-submenu-title {
            color: inherit !important;
          }
          .custom-menu .ant-menu-item:hover,
          .custom-menu .ant-menu-submenu-title:hover {
            background-color: #f5f5f5 !important;
          }
          .custom-menu .ant-menu-item-selected,
          .custom-menu .ant-menu-submenu-selected {
           background-color: #f5f5f5 !important;
          }
          @media (max-width: 768px) {
            .custom-menu {
              width: 100% !important;
            }
          }
        `}
      </style>
      <Menu
        mode="inline"
        defaultSelectedKeys={['profile']}
        openKeys={stateOpenKeys}
        onOpenChange={onOpenChange}
        onClick={onMenuClick}
        items={items}
        theme="light"
        className="custom-menu"
      />
    </>
  );
};

export default SettingsMenu;


