import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import {Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faFileInvoiceDollar, faPrint } from '@fortawesome/free-solid-svg-icons';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom'; 
import { generateExcelFile } from '../../components/ExcelDownload/excelSheet';
import { generatePDFFile } from '../../components/PdfDownload/pdfDownload';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';

const SavingsPayouts = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const componentRef = useRef(null);

  const fetchSavingsPayouts = async () => {
    try {
      setLoadingData(true);
      const { db } = fetchFirebaseConfig();
      
      // Fetch transactions of type "Savings Payout"
      const querySnapshot = await getDocs(query(collection(db, 'PaymentData'), where('transaction', '==', 'Savings Payout')));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Fetch user data for each document and filter out records where userData.display_name is empty
      const updatedData = await Promise.all(fetchedData.map(async (item) => {
        const userDoc = await getDoc(item.userReference);
        const userData = userDoc.exists() ? userDoc.data() : null;
        if (userData && userData.display_name) {
          return {
            ...item,
            id: item.id,
            firstName: userData.display_name,
            middleName: userData.mid_name,
            sirName: userData.sir_name,
            voucher: item.voucher,
          };
        }
        return null; // Return null for data with empty display_name
      }));
  
      // Filter out null values
      const filteredData = updatedData.filter(item => item !== null);
  
      // Sort the data based on the PayoutDate field in descending order
      const sortedData = filteredData.sort((a, b) => b.PayoutDate.seconds - a.PayoutDate.seconds);
  
      setData(sortedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };
  

    useEffect(() => {
    fetchSavingsPayouts();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const renderDateRequested = timestamp => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  const handleVoucherClick = (voucherUrl) => {
    window.open(voucherUrl, '_blank'); // Open the voucher URL in a new tab
  };

  const renderLoanType = (transaction) => {
    return transaction === "Savings Payout" ? (
      <Badge color="warning" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Savings Payout</span>
      </Badge>
    ) : null;
  };

  const handleFilter = async () => {
    setLoadingData(true);
    try {
      const { db } = fetchFirebaseConfig();
      
      // Fetch transactions based on date range
      const querySnapshot = await getDocs(query(collection(db, 'PaymentData'), 
        where('PayoutDate', '>=', new Date(startDate)),
        where('PayoutDate', '<=', new Date(endDate))
      ));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Fetch user data for each document and filter out records where userData.display_name is empty
      const updatedData = await Promise.all(fetchedData.map(async (item) => {
        const userDoc = await getDoc(item.userReference);
        const userData = userDoc.exists() ? userDoc.data() : null;
        if (userData && userData.display_name) {
          return {
            ...item,
            id: item.id,
            firstName: userData.display_name,
            middleName: userData.mid_name,
            sirName: userData.sir_name,
            voucher: item.voucher,
          };
        }
        return null; // Return null for data with empty display_name
      }));
  
      // Filter out null values
      const filteredData = updatedData.filter(item => item !== null);
  
      // Sort the data based on the PayoutDate field in descending order
      const sortedData = filteredData.sort((a, b) => b.PayoutDate.seconds - a.PayoutDate.seconds);
  
      const filteredPayoutsData = sortedData.filter((payout) => payout.transaction === "Savings Payout");
  
      setData(filteredPayoutsData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };
  
  const handleRedo = () => {
    fetchSavingsPayouts();
    setEndDate('');
    setStartDate('');
  };


  const generateExcel = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'name', width: 30 },
      { header: 'Amount Transfered', key: 'Amount', width: 20 },
      { header: 'Payout Type', key: 'transaction', width: 15 },
      { header: 'Payout Date', key: 'PayoutDate', width: 30 },
    ];
  
    const formatters = {
      id: (_, __, index) => index + 1,
      PayoutDate: renderDateRequested,
    };
  
    await generateExcelFile({
      sheetName: 'Savings Payouts',
      title: 'Savings Payouts',
      columns: columns,
      data: data,
      formatters: formatters,
      fileName: 'SavingsPayouts.xlsx',
      numericalColumns: ['Amount'],
      useFullName2: true, // This will use the full name formatter
      useLatestBalance: true, // This will use the latest balance formatter
      customStyles: {
        headerFill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF5F7FA' }
        }
      }
    });
  };


  const generatePDF = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'name', width: 30 },
      { header: 'Amount Transfered', key: 'Amount', width: 20 },
      { header: 'Payout Type', key: 'transaction', width: 15 },
      { header: 'Payout Date', key: 'PayoutDate', width: 30 },
    ];
      
    const formatters = {
      id: (_, __, index) => index + 1,
      createDate: renderDateRequested,
    };
  
    await generatePDFFile({
      title: 'Savings Payouts',
      columns: columns,
      data: data,
      formatters: formatters,
      fileName: 'SavingsPayouts.pdf',
      numericalColumns: ['Amount'],
      useFullName2: true, // This will use the full name formatter
      useLatestBalance: true, // This will use the latest balance formatter
    });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      key: 'name',
      align: 'center',
      render: (_, record) => {
        const capitalizeFirstLetter = (str) => {
          return str.charAt(0).toUpperCase() + str.slice(1);
        };
        const firstName = record.firstName ? capitalizeFirstLetter(record.firstName) : '';
        const middleNameInitial = record.middleName ? record.middleName.charAt(0).toUpperCase() + '.' : '';
        const sirName = record.sirName ? capitalizeFirstLetter(record.sirName) : '';
        const fullName = [firstName, middleNameInitial, sirName].filter(Boolean).join(' ');  
        return (
          <span>{fullName}</span>
        );
      },
    },
    {
      title: 'Amount Transferred',
      dataIndex: 'Amount',
      key: 'Amount',
      align: 'center',
      ...getColumnSearchProps('Amount'),
      sorter: (a, b) => a.Amount - b.Amount,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Payout Type',
      dataIndex: 'transaction',
      key: 'transaction',
      align: 'center',
      ...getColumnSearchProps('transaction'),
      sorter: (a, b) => a.transaction - b.transaction,
      sortDirections: ['descend', 'ascend'],
      render: renderLoanType,
    },
    {
      title: 'Voucher',
      dataIndex: 'voucher',
      key: 'voucher',
      align: 'center',
      render: (text) => (
        <Space>
        <ButtonIcon icon={faFileInvoiceDollar} onClick={() => handleVoucherClick(text)}/>
        </Space>
      ),
    },
    {
      title: 'Payout Date',
      dataIndex: 'PayoutDate',
      key: 'PayoutDate',
      align: 'center',
      sorter: (a, b) => a.PayoutDate - b.PayoutDate,
      sortDirections: ['descend', 'ascend'],
      render: renderDateRequested,
    },
  ];

  return (
    <div>
     <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Savings</Breadcrumb.Item>
        <Breadcrumb.Item>Payouts</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Savings Payouts</h5>
      <WidgetShadow
       title={<h7><span className="fw-semi-bold">Data Range</span></h7>}
      >
         <Row  style={{marginTop:'15px'}}>
           <Col md={6}>
                    <FormGroup>
                      <Label for="exampleFirstName">Start Date</Label>
                      <InputData
                        id="exampleFirstName"
                        name="startDate"
                        placeholder="Start Date"
                        type="date"
                        bsSize="sm"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleMiddleName">End Date</Label>
                      <InputData
                        id="endDate"
                        name="middlename"
                        placeholder="End date"
                        type="date"
                        bsSize="sm"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <TextButton label='Search'  onClick={handleFilter} size='sm'/>
                  <div style={{ marginLeft: '10px' }}></div>
                  <TextButton label='Reset'  onClick={handleRedo} size='sm'/>
                </div>
           </WidgetShadow>
      <WidgetShadow>
      <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonIcon icon={faFileExport} tooltipTitle='Export' onClick={generateExcel} size="sm" style={{marginRight:'5px'}}/>
                <ButtonIcon icon={faFilePdf} tooltipTitle='Pdf' onClick={generatePDF} size="sm" style={{marginRight:'5px'}}/>
                <ReactToPrint
                      trigger={() => (
                        <ButtonIcon icon={faPrint} tooltipTitle='Print' onClick={printing} size="sm"/>
                      )}
                      content={() => componentRef.current} // Specify the component to be printed
                      onBeforeGetContent={() => setPrinting(true)}
                      onAfterPrint={() => setPrinting(false)}
                    />
          </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
          <Table columns={columns} dataSource={data} loading={loadingData} size="small" />
        </div>
      </WidgetShadow>
    </div>
  );
};

export default SavingsPayouts;
