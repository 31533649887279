import React, { useState } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Widget from '../../components/Widget/Widget';
import {fetchFirebaseConfig} from '../../firebase';
import {fetchFirebaseConfig2} from '../../firebaseClient';
import { createUserAndUploadProfile } from './RegisterData';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { TextButton } from '../../components/Buttons/CustomButtons';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';

const Register = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCreatingMember, setIsCreatingMember] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [refereeChecked, setRefereeChecked] = useState(false);
    const [refereeChecked1, setRefereeChecked1] = useState(false);
    const [positionOptions, setPositionOptions] = useState([]);
    const [position, setPosition] = useState("Member");
    const [loanCommitee, setLoanCommmitee] = useState(false);
    const [showRoleInput, setShowRoleInput] = useState(false);
    const [gender, setGender] = useState("Male");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [address, setAddress] = useState("");
    const [contact, setContact] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [sirName, setSirName] = useState("");
    const [dob, setDob] = useState("");
    const [regNo, setRegNo] = useState(null);
    const [refNo, setRefNo] = useState(null);
    const [religion, setReligion] = useState("Christian");
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [refFirstName, setrefFirstName] = useState("");
    const [refMiddleName, setrefMiddleName] = useState("");
    const [refSirName, setrefSirName] = useState("");
    const [refgender, setrefGender] = useState("Male");
    const [refemail, setrefEmail] = useState("");
    const [refcontact, setrefContact] = useState("");
    const [heirFirstName, setheirFirstName] = useState("");
    const [heirMiddleName, setheirMiddleName] = useState("");
    const [heirSirName, setheirSirName] = useState("");
    const [heirgender, setheirGender] = useState("Male");
    const [heiremail, setheirEmail] = useState("");
    const [heircontact, setheirContact] = useState("");
    const [refrelationshipStatus, setrefrelationshipStatus] = useState("");
    const [ heirrelationshipStatus, setheirrelationshipStatus] = useState("");
    const [generatedRefNo, setGeneratedRefNo] = useState("");
    const [accountName, setAccountName] = useState("");
    const [accountNo, setAccountNo] = useState("");
    const [emailError, setEmailError] = useState("");
    const [showEmailErrorAlert, setShowEmailErrorAlert] = useState(false);
    const [showContactErrorAlert, setShowContactErrorAlert] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const history = useHistory();


    const handleAccountName = (event) => {
      const selectedAccountName = event.target.value;
      setAccountName(selectedAccountName);
    };


    const handleReligion = (event) => {
      const selectedReligion = event.target.value;
      setReligion(selectedReligion);
    };

    //create user
    const handleOnSubmit = async (e) => {
      e.preventDefault(); // Prevent form submission and page refresh
    
      setIsSubmitting(true);
    
      const { auth, db, storage } = fetchFirebaseConfig();
      const { auth2, app2 } = fetchFirebaseConfig2();
      setIsCreatingMember(true);
    
      const registrationNo = parseFloat(regNo);
    
      const success = await createUserAndUploadProfile(
        {
          Role: "Admin",
          email,
          password,
          position: 'Member',
          gender,
          selectedPhoto,
          regNo: registrationNo,
          loanCommitee: false,
          display_name: firstName,
          mid_name: middleName,
          sir_name: sirName,
          phone_number: contact,
          location: address,
          religion,
          refNo,
          accountName,
          accountNo,
          dob,
          refFirstName,
          refMiddleName,
          refSirName,
          refemail,
          refcontact,
          refgender,
          refrelationshipStatus,
          heirFirstName,
          heirMiddleName,
          heirSirName,
          heiremail,
          heircontact,
          heirgender,
          heirrelationshipStatus,
        },
        auth2,
        app2,
        db,
        storage
      );
    
      setIsCreatingMember(false);
      setIsSubmitting(false);
    
      if (success) {
        // Clear form inputs
        setEmail("");
        setPassword("");
        setAddress("");
        setContact("");
        setFirstName("");
        setMiddleName("");
        setSirName("");
        setRegNo("");
        setAddress("");
        setReligion("");
        setRefNo("");
        setDob("");
        setrefFirstName("");
        setrefMiddleName("");
        setrefSirName("");
        setrefEmail("");
        setrefContact("");
        setAccountName("");
        setAccountNo("");
        setheirFirstName("");
        setheirMiddleName("");
        setheirSirName("");
        setheirEmail("");
        setheirContact("");
        setrefrelationshipStatus("");
        setheirrelationshipStatus("");
        setSelectedPhoto(null);
    
        setIsSubmitting(false);
    
        toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Member created successfully</div>);
    
        history.push("/app/members/members-list");
      } else {
        toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Error while creating member</div>);
      }
    };
    

  return (
    <div>
     <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Members</Breadcrumb.Item>
        <Breadcrumb.Item>Register Member</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Add new member</h5>
      <WidgetShadow>
        <Row>
          <Col xs={12}>
              <Form className="mt" onSubmit={handleOnSubmit}>
                <Row>
                  <Col md={4}>
                    {/* First Column */}
                    <FormGroup>
                      <Label for="exampleFirstName">First name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleFirstName"
                        name="firstname"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                        required
                        placeholder="First name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Second Column */}
                    <FormGroup>
                      <Label for="exampleMiddleName">Middle name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleMiddleName"
                        name="middlename"
                        onChange={(e) => setMiddleName(e.target.value)}
                        value={middleName}
                        required
                        placeholder="middle name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Third Column */}
                    <FormGroup>
                      <Label for="exampleSirName">Sir name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleSirName"
                        name="Sir-name"
                        onChange={(e) => setSirName(e.target.value)}
                        value={sirName}
                        required
                        placeholder="sir name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {/* Fourth Column */}
                    <FormGroup>
                      <Label for="exampleGender">Gender<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleGender"
                        name="Gender"
                        onChange={(e) => setGender(e.target.value)} 
                        value={gender}
                        required
                        type="select"
                        size='sm'
                      >
                        <option value="Male">male</option>
                        <option value="Female">female </option>
                        </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Fifth Column */}
                    <FormGroup>
                      <Label for="exampleDOB">Date Of Birth<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleDOB"
                        name="dob"
                        onChange={(e) => setDob(e.target.value)}
                        value={dob}
                        placeholder="dob"
                        required
                        bsSize="sm"
                        type="date"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Sixth Column */}
                    <FormGroup>
                      <Label for="exampleUploadImage">Upload Image</Label>
                      <Input
                        id="exampleUploadImage"
                        bsSize="sm"
                        type="file"
                        accept="image/*"
                        onChange={(e) => setSelectedPhoto(e.target.files[0])}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleAccountName">Account name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                          id="exampleAccountName"
                          name="accountName"
                          onChange={handleAccountName}
                          value={accountName.toString()}
                          required
                          type="select"
                          size='sm'
                        >
                        <option value="Mpesa">Mpesa</option>
                        <option value="Tigopesa">Tigopesa</option>
                        <option value="Mpesa">Mpesa</option>
                        <option value="AirtelMoney">AirtelMoney</option>
                        <option value="Halopesa">Halopesa</option>
                        <option value="Tpesa">Tpesa</option>
                        <option value="NMB">NMB</option>
                        <option value="CRDB">CRDB</option>
                        <option value="NBC">NBC</option>
                        </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleAccountNumber">Account number<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleAccountNumber"
                        name="accountNumber"
                        onChange={(e) => setAccountNo(e.target.value)}
                        value={accountNo}
                        placeholder="Account number"
                        required
                        bsSize="sm"
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleRegistration">NIDA Registration number<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleRegistration"
                        name="registration"
                        onChange={(e) => setRegNo(e.target.value)}
                        value={regNo}
                        placeholder="Registrstion number"
                        bsSize="sm"
                        required
                        type='number'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleReligion">Religion<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                          id="exampleReligion"
                          name="religion"
                          onChange={handleReligion}
                          value={religion.toString()}
                          required
                          type="select"
                          size='sm'
                        >
                        <option value="Mpesa">Christian</option>
                        <option value="Tigopesa">Muslim</option>
                        <option value="Tigopesa">Others</option>
                        </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleAddress">Address<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleAddress"
                        name="address"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                        placeholder="Address"
                        required
                        bsSize="sm"
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleReference">Reference no<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleReference"
                        name="reference"
                        onChange={(e) => setRefNo(e.target.value)}
                        value={refNo || generatedRefNo}
                        placeholder="reference no"
                        required
                        bsSize="sm"
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="examplePassword">Password<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="examplePassword"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password || generatedRefNo}
                        placeholder="password"
                        required
                        type="password"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleEmail">Email<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleEmail"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        placeholder="email"
                        required
                        bsSize="sm"
                        type='email'
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleMobile">Mobile<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleMobile"
                        name="mobile"
                        onChange={(e) => setContact(e.target.value)}
                        value={contact}
                        placeholder="mobile"
                        required
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {/* Kin details */}
                <h5 className='mt-4'>Kin details</h5>
                <Row>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleKinfirst">First name</Label>
                      <Input
                        id="exampleKinfirst"
                        name="kinfirstname"
                        onChange={(e) => setrefFirstName(e.target.value)}
                        value={refFirstName}
                        placeholder="first-name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleKinMid">Mid name</Label>
                      <Input
                        id="exampleKinMid"
                        name="kinmidname"
                        onChange={(e) => setrefMiddleName(e.target.value)}
                        value={refMiddleName}
                        placeholder="mid-name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleKinSir">Sir name</Label>
                      <Input
                        id="exampleKinSir"
                        name="kinsirname"
                        onChange={(e) => setrefSirName(e.target.value)}
                        value={refSirName}
                        placeholder="sir-name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleKinGender">Gender</Label>
                      <Input
                        id="exampleKinGender"
                        name="kinGender"
                        onChange={(e) => setrefGender(e.target.value)}
                        value={refgender}
                        type="select"
                        size='sm'
                      >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                        </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleKinEmail">email</Label>
                      <Input
                        id="exampleKinEmail"
                        name="kinEmail"
                        placeholder="email"
                        onChange={(e) => setrefEmail(e.target.value)}
                        value={refemail}
                        type="email"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleKinMobile">Mobile number</Label>
                      <Input
                        id="exampleKinMobile"
                        name="kinMobile"
                        onChange={(e) => setrefContact(e.target.value)}
                        value={refcontact}
                        placeholder="mobile number"
                        type="number"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleKinRelation">Relationship status</Label>
                      <Input
                        id="exampleKinRelation"
                        name="exampleKinRelation"
                        onChange={(e) => setrefrelationshipStatus(e.target.value)}
                        value={refrelationshipStatus}
                        placeholder="Relationship status"
                        bsSize="sm"
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {/* Heir details */}
                <h5 className='mt-4'>Heir details</h5>
                <Row>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleHeirfirst">First name</Label>
                      <Input
                        id="exampleHeirfirst"
                        name="heirfirstname"
                        onChange={(e) => setheirFirstName(e.target.value)}
                        value={heirFirstName}
                        placeholder="first-name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleHeirMid">Mid name</Label>
                      <Input
                        id="exampleHeirMid"
                        name="heirmidname"
                        onChange={(e) => setheirMiddleName(e.target.value)}
                        value={heirMiddleName}
                        placeholder="mid-name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleHeirSir">Sir name</Label>
                      <Input
                        id="exampleHeirSir"
                        name="heirsirname"
                        onChange={(e) => setheirSirName(e.target.value)}
                        value={heirSirName}
                        placeholder="sir-name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleHeirGender">Gender</Label>
                      <Input
                        id="exampleHeirGender"
                        name="heirGender"
                        onChange={(e) => setheirGender(e.target.value)}
                        value={heirgender}
                        type="select"
                        size='sm'
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleHeirEmail">email</Label>
                      <Input
                        id="exampleHeirEmail"
                        name="heirEmail"
                        placeholder="email"
                        onChange={(e) => setheirEmail(e.target.value)}
                        value={heiremail}
                        type="email"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleHeirMobile">Mobile number</Label>
                      <Input
                        id="exampleHeirMobile"
                        name="heirMobile"
                        onChange={(e) => setheirContact(e.target.value)}
                        value={heircontact}
                        placeholder="mobile number"
                        type="number"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="exampleHeirRelation">Relationship status</Label>
                      <Input
                        id="exampleHeirRelation"
                        name="heirRelation"
                        onChange={(e) => setheirrelationshipStatus(e.target.value)}
                        value={heirrelationshipStatus}
                        placeholder="Relationship status"
                        bsSize="sm"
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <TextButton label='Submit'/>
                </div>
              </Form>
          </Col>
        </Row>
      </WidgetShadow>
    </div>
  );
};

export default Register;
