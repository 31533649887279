// src/components/Receipt.js
import React, { useRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Table, Typography, Spin } from 'antd';
import ReactToPrint from 'react-to-print';
import Widget from '../Widget/Widget';
import { ButtonIcon, TextButton } from '../Buttons/CustomButtons';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';

const { Title, Text } = Typography;

function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const randomNumber1 = Math.floor(Math.random() * 1000);
const randomNumber2 = Math.floor(Math.random() * 1000);

const Receipt = ({
  isOpen,
  toggle,
  dataSource,
  columns,
  user,
  userLocation,
  userContacts,
  referenceNumber,
  userDate,
  amountRequested,
  fetchId,
  receiptTitle,
  submit,
  destination,
  submitButtonLabel='Submit',
  closeButtonLabel = "Close"
}) => {
  const componentRef = useRef(null);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{receiptTitle}</ModalHeader>
      <ModalBody>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <ReactToPrint
                            trigger={() => (
                              <ButtonIcon size="sm" tooltipTitle='print' style={{ marginRight: "8px" }} icon={faPrint}/>
                                )}
                                content={() => componentRef.current} // Specify the component to be printed
                              />
                            <ButtonIcon size="sm" tooltipTitle='Export' style={{ marginRight: "8px" }} icon={faFilePdf}/>
         </div>
        <div ref={componentRef} id={fetchId}>
          <Widget>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flex: 1 }}>
           <div style={{ flex: 1 }}>
              <Title style={{ fontSize: '20px' }}><strong>RECEIPT</strong></Title>
            </div>
            <div>
            <img src="DSP.png" alt="Description of the image" style={{ width: '50px', height: '50px' }}/>
            </div>
            </div>
            <hr style={{marginBottom:'-0.5px'}}/>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flex: 1 }}>
            <div style={{ flex: 1 }}>
              <Title level={5} style={{ color: "#7e8d9f", fontSize: '14px' }}>Receipt # &gt; &gt;<strong>NCAA {randomNumber1}-{randomNumber2}</strong></Title>
            </div>
            <div>
              <Title level={5} style={{ color: "#7e8d9f", fontSize: '14px' }}>Payment Date:&nbsp;&nbsp;<strong>{userDate}</strong></Title>
            </div>
            </div>
            <hr style={{marginTop:'-0.5px'}}/>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "24px" }}>
              <div style={{ flex: 1}}>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li><b>{destination}</b>:</li>
                  <li><Text strong style={{ color: "#5d9fc5" }}>&nbsp;{user},</Text></li>
                  <li>&nbsp;{userLocation}, Tanzania,</li>
                  <li><Text strong>&nbsp;{userContacts}</Text></li>
                </ul>
              </div>
              <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li><Text type="secondary"><strong>Reference #:</strong>{referenceNumber}</Text></li>
                  <li><Text type="secondary"><strong>Issued Date:</strong> {userDate}</Text></li>
                  <li><Text type="secondary"><strong>Status:</strong> <span>Approved</span></Text></li>
                </ul>
              </div>
            </div>
            <div style={{ marginBottom: "24px" }}>
              <Table dataSource={dataSource} columns={columns} pagination={false} bordered size='small'/>
            </div>
            <div style={{ marginBottom: "24px", display: "flex", justifyContent: "space-between" }}>
              <p style={{ color: "black" }}>Total Amount&nbsp;:&nbsp;<span style={{ fontSize: "23px", marginLeft: "8px", fontWeight:'bold', color:'#7a7977' }}>&nbsp;TSH&nbsp;{numberWithCommas(amountRequested)}</span></p>
            </div>
            <hr />
          </Widget>
        </div>
      </ModalBody>
      <ModalFooter>
      <TextButton label={submitButtonLabel} onClick={submit}/>
      <Button color="secondary" onClick={toggle}>
          {closeButtonLabel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Receipt;


