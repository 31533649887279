import React, { useEffect, useState, useRef } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../../firebase';
import { FormGroup, Input, Label,Button,ButtonGroup } from 'reactstrap';
import * as FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import Papa from 'papaparse';
import { TextButton } from '../../../components/Buttons/CustomButtons';

const AreaTrendChart = () => {
  const [loanData, setLoanData] = useState([]);
  const [maxAmount, setMaxAmount] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
  const [loading, setLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchLoanData = async () => {
      const { db } = fetchFirebaseConfig();
      const loanQuerySnapshot = await getDocs(collection(db, 'Loans'));
      const processedData = {};
      let maxLoanAmount = 0;

      const allMonths = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      allMonths.forEach(month => {
        processedData[month] = { month, shortTerm: 0, longTerm: 0, chapchap: 0 };
      });

      loanQuerySnapshot.forEach(doc => {
        const loan = doc.data();
        // Add condition to filter out loans with loanState equal to 'Approved'
        if (loan.loanState === 'Approved') {
          const loanAmount = parseFloat(loan.loanAmount1);
          const dateTaken = loan.dateTaken.toDate();
          const year = dateTaken.getFullYear().toString();
          const month = dateTaken.toLocaleString('default', { month: 'long' });

          if (year === selectedYear) {
            if (loanAmount > maxLoanAmount) {
              maxLoanAmount = loanAmount;
            }

            if (loan.loanSchedual === 'Short Term Loan') {
              processedData[month].shortTerm += loanAmount;
            } else if (loan.loanSchedual === 'Long Term Loan') {
              processedData[month].longTerm += loanAmount;
            } else if (loan.loanSchedual === 'Chap Chap') {
              processedData[month].chapchap += loanAmount;
            }
          }
        }
      });

      setMaxAmount(maxLoanAmount);

      const dataArray = Object.values(processedData);

      setLoanData(dataArray);
      setLoading(false);
    };

    fetchLoanData();
  }, [selectedYear]);

  const handleChangeYear = (event) => {
    setSelectedYear(event.target.value);
  };

  const formatYAxisTick = (value) => {
    return value.toLocaleString();
  };

  const formatTooltip = (value, name, props) => {
    return [value.toLocaleString(), name];
  };

  const generateYearOptions = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const handleDownloadImage = () => {
    if (chartRef.current) {
      const chart = chartRef.current.container;
      html2canvas(chart).then(canvas => {
        canvas.toBlob(blob => {
          FileSaver.saveAs(blob, 'loan_area.png');
        });
      });
    }
  };

  const handleDownloadCSV = () => {
    const csv = Papa.unparse(loanData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'loan_area.csv');
  };

  const handleDownloadSVG = () => {
    if (chartRef.current) {
      const svg = chartRef.current.container.querySelector('svg');
      const svgData = new XMLSerializer().serializeToString(svg);
      const blob = new Blob([svgData], { type: 'image/svg+xml' });
      FileSaver.saveAs(blob, 'loan_area.svg');
    }
  };

  return (
    <div>
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <FormGroup className="me-3">
          <Label for="yearSelect">
            <i class="fa fa-calendar" aria-hidden="true"></i>
            <span style={{ fontSize: '13px', marginLeft: '3px' }}> Year</span>
          </Label>
          <Input
            type="select"
            id="yearSelect"
            onChange={handleChangeYear}
            value={selectedYear}
            size="sm"
            style={{ fontSize: '12px' }}
          >
            <option value="">Select Year</option>
            {generateYearOptions(2000, new Date().getFullYear() + 10).map(year => (
              <option key={year} value={year.toString()}>{year}</option>
            ))}
          </Input>
        </FormGroup>
        <ButtonGroup size='sm'>
          <TextButton label='PNG'  onClick={handleDownloadImage}/>
          <TextButton label='SVG'  onClick={handleDownloadSVG}/>
          <TextButton label='CSV'  onClick={handleDownloadCSV}/>
        </ButtonGroup>
      </div>
      {!loading && (
        <ResponsiveContainer width="100%" height={250}>
          <AreaChart
            data={loanData}
            margin={{ top: 10, right: 10, left: 10, bottom: 0 }}
            ref={chartRef}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" tick={{ fontSize: 12 }} />
            <YAxis domain={[0, maxAmount]} tickFormatter={formatYAxisTick} tick={{ fontSize: 12 }} />
            <Tooltip formatter={formatTooltip} wrapperStyle={{ fontSize: '10px' }} contentStyle={{ fontSize: '13px' }} />
            <Area type="monotone" dataKey="shortTerm" stackId="1" stroke="#8884d8" fill="#8884d8" name="Short Term Loan" />
            <Area type="monotone" dataKey="longTerm" stackId="2" stroke="#82ca9d" fill="#82ca9d" name="Long Term Loan" />
            <Area type="monotone" dataKey="chapchap" stackId="3" stroke="#ffc658" fill="#ffc658" name="Chap Chap " />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );  
};

export default AreaTrendChart;
